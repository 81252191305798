<template>
    <div class="col-xl-12 order-xl-1">
        <card shadow type="secondary">

            <div class="col-lg-12" v-if="isLoadingBlade">
                <div class="d-flex justify-content-center">
                    <img :src="loadingBladeUrl" />
                </div>
            </div>

            <h3 class="text-center text-danger" v-if="! hasNotPaidMwanLevy && ! isDiasporaFullReg">
                Note you can skip if you make payment and added it online already,
                else click on <em>Apply Now</em> button
            </h3>

            <div class="pl-lg-4" v-if="! isLoadingBlade && ! hasNotPaidMwanLevy">

                <!-- Import Registration payment data blade here -->
                <RegistrationPaymentData
                    v-bind:applications="application.previous_applications"
                    v-on:reQueryTransaction="reQueryTransaction"
                    v-on:addPayment="addPayment"
                    v-if="! hasPaidRegistration && ! showAddPayment && ! isDiasporaFullReg"
                />

                <div class="text-center"
                    v-if="! hasPaidRegistration && application && application.previous_applications &&
                        application.previous_applications.length > 0 && ! showAddPayment && ! isDiasporaFullReg"
                >
                    <base-button
                        :type="showNewPayment ? 'danger' : 'primary'"
                        @click="showNewPaymentBlade()"
                    >
                        <i class="fa fa-arrow-down" v-if="! showNewPayment"></i>
                        <i class="fa fa-times" v-if="showNewPayment"></i>
                        {{ showNewPayment ? 'Close New Payment' : 'Click Here for New Payment' }}
                    </base-button>
                </div>


                <!-- Import Registration payment blade here -->
                <RegistrationPayment
                    v-bind:application="application.application"
                    v-bind:user="application.user"
                    v-bind:serviceType="application.service_type"
                    v-bind:hasPreviousApplication="hasPreviousApplication"
                    v-bind:previousApplications="application.previous_applications"
                    v-bind:addPayment="addPayment"
                    v-bind:loadApplication="loadApplication"
                    v-bind:displayNewPayment="displayNewPayment"
                    v-bind:changeAppId="changeAppId"
                    v-if="((application && application.previous_applications && application.previous_applications.length == 0) || showNewPayment) 
                            && ! showAddPayment && ! application.isFullReg && ! hasPaidRegistration && ! application.isFullWithoutIntern 
                            && ! isDiasporaFullReg" 
                />

                <!-- Import full reg payment blade... -->
                <FullRegPayment
                    :submitNow="submitNow"
                    v-bind:application="application.application"
                    v-bind:user="application.user"
                    v-bind:serviceType="application.service_type"
                    v-bind:hasPreviousApplication="hasPreviousApplication"
                    v-bind:previousApplications="application.previous_applications"
                    v-bind:addPayment="addPayment"
                    v-bind:showAddPayment="showAddPayment"
                    v-bind:loadApplication="loadApplication"
                    v-bind:displayNewPayment="displayNewPayment"
                    v-bind:changeAppId="changeAppId"
                    v-bind:isAlternativeReg="application.isAlternativeReg"
                    v-if="application.isFullReg && ! application.isFullWithoutIntern && ! hasPaidRegistration && ! isDiasporaFullReg 
                            && (showNewPayment || (application && application.previous_applications && application.previous_applications.length == 0))"
                />

                <!-- Import Full Without Provisional Internship Registration payment blade here -->
                <FullWithoutInternRegPayment
                    :submitNow="submitNow"
                    v-bind:application="application.application"
                    v-bind:user="application.user"
                    v-bind:serviceType="application.service_type"
                    v-bind:hasPreviousApplication="hasPreviousApplication"
                    v-bind:previousApplications="application.previous_applications"
                    v-bind:addPayment="addPayment"
                    v-bind:loadApplication="loadApplication"
                    v-bind:displayNewPayment="displayNewPayment"
                    v-bind:changeAppId="changeAppId"
                    v-if="((application && application.previous_applications && application.previous_applications.length == 0) || showNewPayment)
                            && ! showAddPayment && application.isFullWithoutIntern && ! hasPaidRegistration && ! isDiasporaFullReg"
                />

                <!-- Import Disapora Full Registration payment blade here -->
                <DiasporaRegistrationPayment
                    v-bind:application="application.application"
                    v-bind:user="application.user"
                    v-bind:applicationType="applicationType"
                    v-bind:serviceType="application.service_type"
                    v-bind:hasPreviousApplication="hasPreviousApplication"
                    v-bind:previousApplications="application.previous_applications"
                    v-bind:addPayment="addPayment"
                    v-bind:loadApplication="loadApplication"
                    v-bind:displayNewPayment="displayNewPayment"
                    v-bind:changeAppId="changeAppId"
                    v-if="! hasPaidRegistration && isDiasporaFullReg"
                />

                <AddPayment
                    v-bind:orderNumber="orderNumber"
                    v-bind:application="applicationType"
                    v-bind:loadApplication="loadApplication"
                    v-bind:app_id="applicationId"
                    v-bind:serviceType="serviceType"
                    v-bind:previousApplications="application.previous_applications"
                    :removeAddPayment="removeAddPayment"
                    v-if="! hasPaidRegistration && showAddPayment"
                />

                <div class="col-sm-12 text-center">
                    <base-alert :type="errorType" v-if='showErrorStatus'>
                        <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                        <span class="alert-inner--text">
                            {{ errorMsg }}
                        </span>
                    </base-alert>
                </div>

                <div class="col-lg-12" v-if="hasPaidRegistration">
                    <div class="d-flex justify-content-center">
                        <img :src="completeImageUrl" />

                    </div>
                    <br>
                    <h2 class="text-uppercase text-center">
                        You have completed the registration process
                    </h2>
                    <h3 class="text-center text-danger">
                        Kindly hit the submit button to submit your application for approval.
                    </h3>
                </div>

            </div>

            <MwanPayment
                :loadApplication="loadApplication"
                v-if="hasNotPaidMwanLevy && ! isLoadingBlade"
            />

            <hr class="my-4" v-if="! isLoadingBlade && ! hasNotPaidMwanLevy"/>

            <div class="pl-lg-4" v-if="! isLoadingBlade">
                <hr>
                <base-button
                    type="danger"
                    v-if="! hasPaidRegistration"
                    @click="showPreviousPage()"
                >
                    <i class="fa fa-arrow-left"></i>
                    Previous
                </base-button>
                <div class="right" v-if="hasPaidRegistration">
                    <base-button
                        type="success"
                        @click="submitNow(true)"
                    >
                        <i class="fa fa-save"></i>
                        Submit Now
                    </base-button>
                </div>

                <div class="right" v-if="! hasPaidRegistration && isOldDoctor && ! isDiasporaFullReg">
                    <base-button
                        type="success"
                        @click="submitNow()"
                    >
                        <i class="fa fa-save"></i>
                        Dashboard Now
                    </base-button>
                </div>
            </div>

        </card>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'; //AddQualPayment
    import RegistrationPayment from './RegistrationPayment.vue';
    import RegistrationPaymentData from './RegistrationPaymentData.vue';
    import AddPayment from './AddPayment.vue';
    import FullRegPayment from './FullRegPayment.vue';
    import FullWithoutInternRegPayment from './FullWithoutInternRegPayment';
    import DiasporaRegistrationPayment from './DiasporaRegistrationPayment.vue';
    import MwanPayment from './MwanPayment.vue';
    import swal from 'sweetalert2';// import sweet alert
    import moment from 'moment';
    import axios from 'axios';

    export default {
        components: {
            RegistrationPayment,
            RegistrationPaymentData,
            AddPayment,
            FullRegPayment,
            FullWithoutInternRegPayment,
            MwanPayment,
            DiasporaRegistrationPayment
        },
        name: 'RegistrationDetails',
        props: ['displayNMALevy', 'isDiasporaFullReg'],
        data() {
            return {
                id: this.$route.params.id,
                application: null,
                applicationType: null,
                isLoadingBlade: true,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                showNewPayment: false,
                showAddPayment: false,
                orderNumber: null,
                orderApplication: {},
                selectedAppId: null,
                serviceType: null
            }
        },
        filters: {
            formatPrice(value) {
                if(value) {
                    let val = (value/1).toFixed(2).replace(',', '.');
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                } else {
                    return 'N/A';
                }
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'completeImageUrl', 'user']),
            hasPaidRegistration() {
                return this.application ? this.application.has_paid : false;
            },
            hasPreviousApplication() {
                return this.application && this.application.previous_applications &&
                    this.application.previous_applications.length > 0
            },
            isOldDoctor() {// check if user is old doctor
                let year = this.user && this.user.basic_profile ? this.user.basic_profile.year_of_graduation : false;
                return (moment().diff(year, 'years') > 10);
            },
            applicationId() {// one previous application id
                if(this.selectedAppId) {// get exact application type
                    return this.selectedAppId;
                } else {
                    return this.application && this.application.previous_applications && this.application.previous_applications.length > 0 ?
                        this.application.previous_applications[0].id : null
                }
            },
            hasNotPaidMwanLevy() {// if doctor is medical women and has not paid levy
                const mwan = this.user && this.user.basic_profile && this.user.basic_profile.mwan_levy ? this.user.basic_profile.mwan_levy : false;
                return mwan && mwan.status && ! mwan.pay_status;
            }
        },
        methods: {
            ...mapActions(['getUserRegistrationDetails', 'saveApplicationTransaction', 'getRemitaOrderPaymentStatus', 'submitApplication', 
                'getFlutterwaveTransaction']),
            changeAppId(id, type, serviceCode) {// change application id
                this.selectedAppId = id;
                this.applicationType = type;
                this.serviceType = serviceCode;
            },
            displayNewPayment(value) {// show new payment blade
                return this.showNewPayment = value;
            },
            removeAddPayment() {// remove add payment
                return this.showAddPayment = false;
            },
            showPreviousPage() {// go to next page
                return this.displayNMALevy(true);
            },
            showNewPaymentBlade() {// show new payemnt template
                this.showNewPayment = ! this.showNewPayment;
            },
            submitNow(status = false) {// submit application
                this.preloader();// show loading
                this.submitApplication().then((res) => {
                    if(res.status) {
                        let msg = status ? 'Application has been submitted for approval' : 'Profile has been updated successfully';
                        swal.fire('Account Updated', msg, 'success');
                        return window.location.href='/';
                    } else {
                        let msg = 'An error occured while submitting application, try again';
                        swal.fire('Error Occurred!', msg, 'warning');
                        this.loadApplication();// load application
                    }
                }).
                catch(() => {
                    let msg = 'An error occured while submitting application, try again';
                    swal.fire('Error Occurred!', msg, 'warning');
                    this.loadApplication();// load application
                });
            },
            loadApplication() {// get application
                this.getUserRegistrationDetails().then((res) => {
                    this.application = res;
                    this.applicationType = res.application;
                    this.serviceType = res.service_type;
                    this.isLoadingBlade = false;
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.showErrorStatus = true;
                    this.errorMsg = "Network Failure!, kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            reQueryTransaction(number) {   
                if(this.isDiasporaFullReg) {
                    this.requeryFlutterwaveTransaction(number);// for flutterwave requery
                } else {// for remita requery
                    this.preloader();// show loading
                    this.getRemitaOrderPaymentStatus(number).then((res) => {// save transaction
                        if(res.status) {
                            swal.fire('Payment Successful', res.msg, 'success');
                        } else {
                            swal.fire('Payment Failed', res.msg, 'warning');
                        }
                        this.loadApplication();// load application
                    }).
                    catch(() => {
                        let message = 'Network Failure, kindly reload page and try again';
                        swal.fire('Error ocurred', message, 'warning');
                        this.loadApplication();// load application
                    });
                }                
            },
            async requeryFlutterwaveTransaction(number) {
                // this.preloader();// show loading

                // let res = await axios.post("https://api.flutterwave.com/v3/transactions/288200108/verify", {}, { 
                //         headers: {
                //             Authorization: `Bearer FLWSECK_TEST-af9d115fe983cb0e5a539b54663f1686-X`
                //         }
                //     });// make api call


                //     console.log(res)


            },
            addPayment(number) {// add RRR
                this.orderNumber = number;
                this.showAddPayment = true;
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadApplication();// load application
        },
    }
</script>

<style scoped>
.right {
    float: right;
}
</style>