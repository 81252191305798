import { render, staticRenderFns } from "./NewApplications.vue?vue&type=template&id=6d7e276a&scoped=true&"
import script from "./NewApplications.vue?vue&type=script&lang=js&"
export * from "./NewApplications.vue?vue&type=script&lang=js&"
import style0 from "./NewApplications.vue?vue&type=style&index=0&id=6d7e276a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d7e276a",
  null
  
)

export default component.exports