<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>


        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">

                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h5 class="mb-0 text-uppercase">
                                        Pending Application for
                                        <em
                                            class="text-danger"
                                            v-if="application && application.application_type"
                                        >
                                            {{ application.application_type.name }}
                                        </em>
                                    </h5>
                                </div>
                                <div class="col-4 text-right">
                                    <router-link to="/applications" class="btn btn-sm btn-danger">
                                        <i class="fa fa-reply"></i> Back
                                    </router-link>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>

                        <!-- import edu records details template here -->
                        <ApplyAddQualification
                            v-if="application && application.application_type && ((application.application_type.code == 'additional-pgq') 
                                    || (application.application_type.code == 'additional-pgq-diaspora'))" 
                        />

                        <!-- import Apply Change of Name template here -->
                        <ApplyChangeName
                            v-if="application && application.application_type && ((application.application_type.code == 'change-of-name') 
                                    || (application.application_type.code == 'change-of-name-diaspora'))"
                        />

                        <!-- import Apply Revalidation template here -->
                        <ApplyRevalidation
                            v-if="application && application.application_type &&
                                application.application_type.code == 'reval-reg'"
                        />

                        <!-- import Apply for LOGs template here -->
                        <Logs
                            v-if="application && application.application_type &&
                                application.application_type.code == 'cert-good-standing'"
                        />

                        <!-- import Apply Eics template here -->
                        <ApplyEics
                            v-if="application && application.application_type &&
                                application.application_type.code == 'eics-verification'"
                        />

                        <!-- import Apply Life License template here -->
                        <ApplyLifeLicense
                            v-if="application && application.application_type &&
                                application.application_type.code == 'life-license'"
                            v-bind:applicationType="application.application_type"
                        />

                        <!-- import Apply CTC Full Registration template here -->
                        <ApplyCtcFullReg
                            v-if="application && application.application_type &&
                                application.application_type.code == 'ctc-full-reg'"
                        />

                        <!-- import Apply Full Without Provisional Registration (Internship) template here -->
                        <ApplyFullWithoutIntern
                            v-if="application && application.application_type &&
                                application.application_type.code == 'full-intern-reg'"
                        />

                        <!-- import Apply Full Without Provisional Registration (Internship) template here -->
                        <ApplyFullWithoutAq
                            v-if="application && application.application_type &&
                                application.application_type.code == 'full-aq-reg'"
                            :applicationType="application.application_type"
                        />

                        <!-- import Apply Temporary/Limited Registration template here -->
                        <PendingTempReg
                            v-if="application && application.application_type &&
                                ((application.application_type.code == 'limited-reg') ||
                                (application.application_type.code == 'limited-reg-long')
                                || (application.application_type.code == 'limited-reg-renewal')
                                || (application.application_type.code == 'limited-reg-long-renewal'))"
                            :applicationType="application.application_type"
                        />

                        <!-- import Apply Diaspora License template here -->
                        <ApplyDiasporaLicense
                            :applicationType="application.application_type"
                            :type="'pending-app'"
                            v-if="application && application.application_type &&
                                application.application_type.code == 'diaspora-license'"
                        />

                        <div class="col-sm-12 text-center" v-if='showErrorStatus'>
                            <base-alert :type="errorType">
                                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                                <span class="alert-inner--text">
                                    {{errorMsg}}
                                </span>
                            </base-alert>
                        </div>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import ApplyAddQualification from '../NewApplications/ApplyAddQualification.vue';
    import Logs from '../NewApplications/Logs.vue';
    import ApplyChangeName from '../NewApplications/ApplyChangeName';
    import ApplyLifeLicense from '../NewApplications/ApplyLifeLicense';
    import ApplyRevalidation from '../NewApplications/ApplyRevalidation';
    import ApplyEics from '../NewApplications/ApplyEics';
    import ApplyCtcFullReg from '../NewApplications/ApplyCtcFullReg';
    import ApplyFullWithoutIntern from '../NewApplications/ApplyFullWithoutIntern';
    import ApplyFullWithoutAq from '../NewApplications/ApplyFullWithoutAq';
    import PendingTempReg from './PendingTempReg.vue';
    import ApplyDiasporaLicense from '../NewApplications/ApplyDiasporaLicense/ApplyDiasporaLicense.vue';

    export default {
        components: {
            ApplyAddQualification,
            Logs,
            ApplyChangeName,
            ApplyLifeLicense,
            ApplyRevalidation,
            ApplyEics,
            ApplyCtcFullReg,
            ApplyFullWithoutIntern,
            ApplyFullWithoutAq,
            PendingTempReg,
            ApplyDiasporaLicense
        },
        name: 'PendingApplication',
        data() {
            return {
                id: this.$route.params.id,
                application: null,
                isLoadingBlade: true,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl'])
        },
        methods: {
            ...mapActions(['getApplication']),
            loadApplication() {// get application
                this.getApplication({'id' : this.id}).then((res) => {
                    this.application = res;
                    this.isLoadingBlade = false;
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.showErrorStatus = true;
                    this.errorMsg = "Network Failure!", "Kindly reload the page...";
                    this.errorType = 'danger';
                });
            }
        },
        created() {
            this.loadApplication(); // load application
        },
    }
</script>