<template>
    <div>
        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>


        <div v-if="! isLoadingBlade">
            <div class="row align-items-center" v-if="application && application">
                <div class="col-8">
                    <h4 class="mb-0 text-uppercase">
                        Make Payment for
                        <em class="text-danger">
                            {{ application.application.name }}
                        </em>
                    </h4>
                </div>
                <div class="col-4 text-right">
                    <router-link to="/applications" class="btn btn-sm btn-danger">
                        <i class="fa fa-reply"></i> Back
                    </router-link>
                </div>
            </div>

            <div class="container ct-example-row" v-if="! showAddPayment">
                <div class="row" v-if="application && application.application && application.application.has_payment">
                    <div class="col-lg-12">
                        <ul class="list-unstyled">
                            <li>
                                <hr class="my-4">
                                <h4
                                    class="text-center text-danger"
                                    v-if="application.license && application.license.surcharge"
                                >
                                    Note: If you make payment on or before 31st Decemeber
                                    {{ application.license.license_year - 1 }},
                                    and you have the RRR number, kindly proceed to add the RRR payment.
                                    As the surge amount will not affect you.
                                </h4>
                                <br>
                                <h3 class="text-center">
                                    Application Name: &nbsp;
                                    <em class="text-capitalize font-weight-light">
                                        {{ application.application.name }}
                                    </em>
                                </h3>
                                <h3 class="text-center"
                                    v-if="application.license && application.license.license_year"
                                >
                                    License Year: &nbsp;
                                    <em class="text-capitalize font-weight-light">
                                        {{ application.license.license_year }}
                                    </em>
                                </h3>
                                <br>
                                <h3 class="text-center">
                                    Total Amount: &nbsp;
                                    <em class="text-capitalize font-weight-light">
                                        {{ totalAmount | formatPrice }}
                                    </em>
                                </h3>

                                <div v-if="application.application.has_payment.has_breakdowns.length > 0">
                                    <hr class="my-4">
                                    <h1 class="text-center">
                                        Payment Details
                                    </h1>
                                    <hr class="my-4">
                                    <h3
                                        v-for="breakdown in application.application.has_payment.has_breakdowns"
                                        :key="breakdown.id"
                                    >
                                        {{ breakdown.name }}: &nbsp;
                                        <em class="text-capitalize font-weight-light right">
                                            {{ breakdown.amount | formatPrice }}
                                        </em>
                                        <br>
                                    </h3>
                                </div>

                                <h3 v-if="application.postage_fee && application.postage_fee.fee">
                                    Postage Fee
                                    {{ application.postage_fee.country ?
                                            'for '+application.postage_fee.country.name : '' }}: &nbsp;
                                    <em class="text-capitalize font-weight-light right">
                                        {{ application.postage_fee.fee | formatPrice }}
                                    </em>
                                </h3>

                                <h3
                                    class="text-danger"
                                    v-if="application.license && application.license.surcharge &&
                                        application.application.has_payment.surcharge"
                                >
                                    Surcharge (Late Payment): &nbsp;
                                    <em class="text-capitalize font-weight-light right">
                                        {{ application.application.has_payment.surcharge | formatPrice }}
                                    </em>
                                </h3>

                                <h3
                                    v-show="tempPostages && tempPostages.length > 0"
                                    v-for="(postage, index) in tempPostages"
                                    :key="index"
                                >
                                    International Verification Fee
                                    (<em class="text-danger">{{ postage.name }}</em>): &nbsp;
                                    <em class="text-capitalize font-weight-light right">
                                        {{ postage.postage_fee | formatPrice }}
                                        </em>
                                    <br>
                                </h3>

                                <hr class="my-4">
                                <h3>
                                    Total: &nbsp;
                                    <em class="text-capitalize font-weight-light right">
                                        {{ totalAmount | formatPrice }}
                                    </em>
                                </h3>
                                <hr class="my-4">

                                <div class="col-sm-12 text-center">
                                    <base-alert :type="errorType" v-if='showErrorStatus'>
                                        <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                                        <span class="alert-inner--text">
                                            {{ errorMsg }}
                                        </span>
                                    </base-alert>
                                </div>

                                <h3 class="text-danger"
                                    v-if="showPaymentForm"
                                >
                                    Note: If you want to generate RRR for this payment and pay at designated banks, kindly click on
                                    <em class="text-dark">Black</em> button. However, if you have made payment already, you can add your
                                    payment to the application by clicking on the <em class="text-primary">Purple</em> button.
                                    <hr>
                                    Note: When you Generate RRR, you are to proceed to the designated banks to make payment. After
                                    succesful payment, click on the <em class="text-primary">I have Paid, Add RRR Now</em> button
                                    to add your payment.
                                </h3>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-12">
                        <hr class="my-4" v-if="showPaymentForm">

                        <form
                            :action="remitaPaymentUrl" method="POST"
                            v-if="showPaymentForm"
                        >
                            <input
                                type="hidden"
                                v-for="(item, index) in paymentRecords"
                                :key="index"
                                :name="item.title"
                                :value="item.value"
                            />


                            <base-button
                                type="success"
                                nativeType="submit"
                            >
                                <i class="fa fa-credit-card"></i>
                                {{ isloading ? 'Please Wait...' : 'Pay Directly Online Now'}}
                            </base-button>

                            <div class="right">
                                <base-button
                                    type="dark"
                                    @click="addPayment(orderId, true)"
                                >
                                    <i class="fa fa-history"></i>
                                    Generate RRR &amp; Pay at the Bank
                                </base-button>

                                <base-button
                                    type="primary"
                                    @click="addPayment(orderId, false)"
                                >
                                    <i class="fa fa-plus"></i>
                                    I have Paid, Add RRR Now
                                </base-button>
                            </div>

                        </form>

                        <base-button
                            type="success"
                            @click="createApplication()"
                            v-if="! showPaymentForm"
                        >
                            <i class="fa fa-reply"></i>
                            {{ isloading ? 'Please Wait...' : 'Apply Now'}}
                        </base-button>
                    </div>
                </div>
            </div>

            <AddPayment
                :orderNumber="orderNumber"
                :app_id="applicationId"
                :serviceType="serviceType"
                :applicationName="application.application.name"
                :applicationCode="application.application.code"
                :applicationAmount="totalAmount"
                :applicationSurcharge="application.application.has_payment.surcharge"
                :license="application.license"
                v-on:removeAddPayment="removeAddPayment"
                :removeAddPayment="removeAddPayment"
                :isDirectPaymentPage="isDirectPaymentPage"
                :showGeneratePage="showGeneratePage"
                v-if="showAddPayment"
            />
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import moment from 'moment';
    import AddPayment from './AddPayment.vue';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        components: {
            AddPayment
        },
        name: 'OtherPayment',
        data() {
            return {
                id: this.$route.params.id,
                application: null,
                user: {},
                isLoadingBlade: true,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                orderId: null,
                serviceType: null,
                showPaymentForm: false,
                showAddPayment: false,
                trans_no: null,
                applicationId: null,
                isDirectPaymentPage: false,
                showGeneratePage: false
            }
        },
        filters: {
            formatPrice(value) {
                if(value) {
                    let val = (value/1).toFixed(2).replace(',', '.');
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+ ' NGN';
                } else {
                    return 'N/A';
                }
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'remitaPaymentUrl', 'remitaMerchantId',
                'remitaPaymentCallbackUrl', 'remitaApiKey']),
            isAnnualLicense() {// is annual license payment 
                let code = this.application && this.application.application ? this.application.application.code : false;
                if(code) {
                    let validCodes = ['annual-license', 'annual-license-old'];
                    return ($.inArray(code, validCodes) > -1) ? true : false;
                }
                return code;
            },
            gethash() {
                var sha512 = require('js-sha512');
                let text = this.remitaMerchantId+this.serviceType.remita_code+this.orderId
                            +this.totalAmount+this.remitaPaymentCallbackUrl+this.remitaApiKey;
                return sha512(text);
            },
            totalAmount() {// get total amount
                if(this.application.application.code == "eics-verification") {// for Eics only
                    let appAmt = this.application.application.has_payment.amount;
                    let postageFee = this.application.postage_fee.fee;
                    return parseInt(appAmt) + parseInt(postageFee);
                } else {// others
                    let totalAmt = (parseInt(this.application.application.has_payment.amount) +
                                    parseInt(this.application.application.has_payment.surcharge));// get total amount with surcharge
                    let amount = this.application.license && this.application.license.surcharge ? totalAmt :
                                parseInt(this.application.application.has_payment.amount);// get exact amount 
                    if(this.isAnnualLicense || this.isTempRenewalApplication) return amount;// check for annual license payment / temporary renewal application
                    return amount + this.totalTempPostageFee;
                }
            },
            isTempRenewalApplication() {// check if it is temporary renewal application
                let code = this.application && this.application.application ? this.application.application.code : null;
                let validCodes = ["limited-reg-long-renewal", "limited-reg-renewal"];
                return validCodes.includes(code);
            },
            tempPostages() {// get postage fees for temp doctor
                let fees = this.application && this.application.application 
                            ? this.application.application.temp_postage_fees : false;
                if(this.isAnnualLicense || this.isTempRenewalApplication) return [];// exempt temp Postages feee for annual license payment / temporary renewal application         
                return fees ? [ fees.medical_school, fees.aq_country ] : [];
            },
            totalTempPostageFee() {// total postage fee for temp doctor
                let fees = this.application && this.application.application 
                            ? this.application.application.temp_postage_fees : false;
                if(fees) {
                    let amount = (fees.medical_school ? parseInt(fees.medical_school.postage_fee) : 0);
                    amount += (fees.aq_country ? parseInt(fees.aq_country.postage_fee) : 0);
                    return amount;
                }
                return 0;
            },
            paymentRecords() {// get payment records
                let fullName = this.application.user.basic_profile.first_name+' '+
                        this.application.user.basic_profile.last_name;
                return [
                    {'title' : 'merchantId', 'value' : this.remitaMerchantId},
                    {'title' : 'serviceTypeId', 'value' : this.serviceType.remita_code},
                    {'title' : 'amt', 'value' : this.totalAmount},
                    {'title' : 'responseurl', 'value' : this.remitaPaymentCallbackUrl},
                    {'title' : 'hash', 'value' : this.gethash},
                    {'title' : 'payerName', 'value' : fullName},
                    {'title' : 'paymenttype', 'value' : ''},
                    {'title' : 'payerEmail', 'value' : this.application.user.email},
                    {'title' : 'payerPhone', 'value' : this.application.user.basic_profile.phone},
                    {'title' : 'orderId', 'value' : this.orderId},
                ]
            },
            licenseYear() {// get license year
                return this.application && this.application.license ? this.application.license.license_year : null;
            }
        },
        methods: {
            ...mapActions(['getUserApplicationDetails', 'createUserApplication', 'fetchUserProfile', 'internshipStatus',
            'saveApplicationTransaction', 'updateTransNo']),
            getTotalAmount(data) {// get total amount
                return (data && data.surcharge) ? parseInt(data.amount) + parseInt(data.surcharge)
                                                    : parseInt(data.amount);
            },
            isValidAnnualLicense(app) {// check if license can be purchased
                let appType = app && app.application ? app.application : false;
                let code = appType && appType.code ? appType.code : false;
                let validCode = ((code == 'annual-license-old') || (code == 'annual-license'));
                let licenseYear = app && app.license && app.license.license_year ? app.license.license_year : false;
                let maximunYear = moment().year() + 1;
                return validCode && licenseYear && (licenseYear > maximunYear);
            },
            loadApplication(status = false) {// get application 
                this.showAddPayment = false;
                this.showErrorStatus = false;
                this.getUserApplicationDetails(this.id).then((res) => {// get records
                    this.application = res;
                    this.serviceType = res.service_type;
                    if(res && res.application && res.application.code && ((res.application.code == 'full-reg-upgrade') 
                            || (res.application.code == 'full-reg'))) {// check if provisional doctor is upgrading to full, has completed internship
                        this.internshipStatus().then((response) => {// check internship status
                            if(response.status) {// proceed to get paymentdetails
                                return this.proceedWithPayment(status, res);// proceed
                            } else {// show the error message
                                this.isLoadingBlade = false;
                                this.showMsg('danger', res.msg);
                            }
                        }).
                        catch(() => {
                            this.isLoadingBlade = false;
                            this.showMsg('danger', "Network Failure!, kindly reload the page...");
                        });
                    } else {
                        return this.proceedWithPayment(status, res);// proceed
                    }                                     
                }).
                catch(() => {
                    this.showMsg('danger', "Network Failure!, kindly reload the page...");
                });                               
            },
            proceedWithPayment(status, res) {// proceed with payment function
                if(this.isValidAnnualLicense(res)) {// redirect to my license
                    return window.location.href = '/my-license';
                } else { 
                    this.isloading = false;
                    this.isLoadingBlade = false;
                    if(res.previous_applications && res.previous_applications.length > 0) {// update trans no
                        let record = res.previous_applications[0];
                        if(record.payment_status == 'not-paid') {// check for unpaid previous app
                            this.trans_no = record.trans_no;
                            this.applicationId = record.id; 
                            ! status ? this.createApplication(this.applicationId) : this.isLoadingBlade = false; // create application 
                        } else {
                            ! status ? this.createApplication(this.applicationId) : this.isLoadingBlade = false; // create application
                        }
                    } else {
                        ! status ? this.createApplication(this.applicationId) : this.isLoadingBlade = false; // create application
                    }
                } 
            },
            showMsg(type, msg) {
                this.showErrorStatus = true;
                this.errorType = type;
                this.errorMsg = msg;
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
            createApplication(id) {// create application
                this.preloader();// show loading
                this.isloading = true;
                if(id) {
                    this.updateTransNo({'id' : id, 'license_year' : this.licenseYear})
                        .then((res) => {//update trans no
                            this.isloading = false;
                            this.orderId = res.trans_no;
                            this.showPaymentForm = true;
                            swal.close();
                            // let message = 'Kindly click on Pay Now to make payment online';
                            // swal.fire('Application Updated!', message, 'success');
                        }).
                    catch(() => {
                        this.isloading = false;
                        let message = 'Network Failure!, kindly reload the page...';
                        swal.fire('Network Failure!', message, 'warning');
                    });
                } else {
                    let data = {};
                    data.update = this.trans_no ? this.trans_no : false;
                    data.user_data_id = this.$store.state.user.basicProfile.id;// get user data id
                    data.application_type_id = this.application.application.id;// apllication type id
                    data.trans_no = this.genRandomString();// generate trans no
                    data.application_date = this.getCurrentDate();// get current date
                    data.license_year = this.application.license && this.application.license.license_year
                        ? this.application.license.license_year : null;// get license year
                    this.createUserApplication(data).then((res) => {// create application
                        this.isloading = false;
                        if(res.status) {// update transaction
                            this.showPaymentForm = true;
                            this.applicationId = res.application.id;
                            this.orderId = data.trans_no;
                            swal.close();
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        let message = 'Network Failure!, kindly reload the page...';
                        swal.fire('Network Failure!', message, 'warning');
                        return false;
                    });
                }
            },
            getCurrentDate() {// get current date time
                let date = new Date().toJSON().slice(0,10).replace(/-/g,'-');
                return moment(date).format('YYYY-MM-DD');
            },
            removeAddPayment(status = false) {// remove add payment
                this.loadApplication(status);// load application
                return this.showAddPayment = false;
            },
            genRandomString() {
                var length = 12;
                var text = '';
                var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
                for (var i=0; i < length; i++) {
                    text += possible.charAt(Math.floor(Math.random() * possible.length));
                }
                return text;
            },
            makePayment() {// online payment function
                return this.createApplication();// create application
            },
            getCurrentDateTime() {// get current date time
                let date = new Date().toJSON().slice(0,10).replace(/-/g,'-');
                return moment(date).format('YYYY-M-D h:mm:ss');
            },
            addPayment(number, status) {// add RRR
                this.orderNumber = number;
                this.showAddPayment = true;
                this.isDirectPaymentPage = true;
                this.showGeneratePage = status;
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        mounted() {
            this.loadApplication();// load application 
        },
    }
</script>

<style scoped>
.right {
    float: right;
}
</style>