<template>
    <div>
        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div v-if="! isLoadingBlade">
            <h4
                class="mb-0 text-uppercase text-center text-danger"
            >
                Note: Only Doctor applying for Full Without Provisional Registration (with Additional
                Qualification) should fill this part, others can skip below...
            </h4>

            <hr class="my-4">

            <div class="container">
                <div class="row" v-if='! showErrorStatus'>
                    <div class="col-lg-6">
                        <base-input alternative=""
                            label="Country"
                            required
                        >
                            <autocomplete
                                input-class="form-control form-control-alternative"
                                placeholder="Type Country"
                                showNoResults
                                :source="locations.countries"
                                v-model="record.country_id"
                                :initialDisplay="(hasCountry ? hasCountry.name : '')"
                                @selected="changeCountry"
                            >
                            </autocomplete>
                        </base-input>
                    </div>

                    <div class="col-lg-6">
                        <base-input alternative=""
                            label="School Name / Institution (Postgraduate College)"
                            required
                        >
                            <autocomplete
                                ref="schoolAutocomplete"
                                input-class="form-control form-control-alternative"
                                placeholder="Type to search Institution"
                                showNoResults
                                :disableInput="schools && schools.length == 0"
                                :source="schools"
                                :initialDisplay="record.school"
                                resultsValue="name"
                                @selected="changeSchool"
                            >
                            </autocomplete>
                        </base-input>
                    </div>

                    <div class="col-lg-6" v-if="showOtherInstitution">
                        <base-input alternative=""
                            label="Other School / Institution Name"
                            placeholder="Enter Other School / Institution Name"
                            input-classes="form-control-alternative"
                            v-model="record.other_school_name"
                            required
                        />
                    </div>

                    <!-- <div class="col-lg-6">
                        <base-input alternative=""
                            label="Category"
                            required
                            >
                            <select
                                class="form-control form-control-alternative"
                                v-model="record.category"
                                @change="getQualifications()"
                            >
                                <option value="null" disabled>--- Select One ---</option>
                                <option
                                    v-show="categories && categories.length > 0 && category.has_qualifications
                                        && category.has_qualifications.length > 0"
                                    v-for="(category, index) in categories"
                                    :key="index"
                                    :value="category.id"
                                >
                                    {{ category.name }}
                                </option>
                            </select>
                        </base-input>
                    </div> -->

                    <div class="col-lg-12" v-if="qualifications && qualifications.length > 0">
                        <base-input alternative=""
                            label="Qualification"
                            required
                            >

                            <select
                                class="form-control form-control-alternative"
                                v-model="record.qualification"
                                @change="getDegrees()"
                            >
                                <option value="null" disabled>--- Select One ---</option>
                                <option
                                    v-for="qualification in qualifications"
                                    :key="qualification.id"
                                    :value="qualification.id"

                                >
                                    <span class="text-capitalize">{{ capitalizeFirstLetter(qualification.course_title) }}</span>
                                </option>
                            </select>
                        </base-input>
                    </div>


                    <div
                        class="col-lg-12"
                        v-if="(degrees && degrees.length > 0) || record.degree"
                    >
                        <base-input alternative=""
                            v-if="degrees[0] != ''"
                            label="Degree"
                            required
                            >
                            <div class="container">
                                <div class="row">
                                    <base-radio
                                        v-for="(degree, index) in degrees"
                                        :key="index"
                                        v-show="degree != ''"
                                        :name="degree.trim()"
                                        class="mb-3"
                                        v-model="record.degree"
                                    >
                                        {{ degree.trim() }} &nbsp;&nbsp;&nbsp;
                                    </base-radio>
                                </div>
                            </div>

                        </base-input>
                    </div>

                    <div class="col-lg-6">
                        <base-input
                            label="Date of Add. Qualification (Date you obtained the qualification to be registered)"
                            addon-left-icon="ni ni-calendar-grid-58"
                            required

                        >
                            <flat-picker
                                slot-scope="{focus, blur}"
                                @on-open="focus"
                                @on-close="blur"
                                :config="config"
                                class="form-control datepicker"
                                v-model="record.qualify_date"
                            >
                            </flat-picker>
                        </base-input>
                    </div>

                    <div class="col-lg-6">
                        <base-input alternative=""
                            label="Licencing Body"
                            placeholder="Enter Licencing Body"
                            input-classes="form-control-alternative text-capitalize"
                            v-model="record.licencing_body"
                        />
                    </div>

                    <div class="col-lg-6">
                        <base-input alternative=""
                            label="Duration of training (In weeks)"
                            placeholder="Enter Duration of training"
                            input-classes="form-control-alternative text-capitalize"
                            v-model="record.duration"
                            type="number"
                        />
                    </div>

                    <div class="col-lg-6">
                        <base-input
                            label="Notification Date"
                            addon-left-icon="ni ni-calendar-grid-58"
                            required
                        >
                            <flat-picker
                                slot-scope="{focus, blur}"
                                @on-open="focus"
                                @on-close="blur"
                                :config="config"
                                class="form-control datepicker"
                                v-model="record.notification_date"
                            >
                            </flat-picker>
                        </base-input>
                    </div>
                    <div class="col-lg-12"><hr></div>

                    <div
                        class="col-lg-4 text-center borderLine"
                        v-for="(document, index) in documents"
                        :key="index"
                    >
                        <br>
                        <input
                            type="file"
                            id="file" style="display: none"
                            @change="onFileSelected"
                        >
                        <h4 class="text-capitalize height-50" v-html="document.name"></h4><br>
                        <base-button
                            size="sm" type="primary"
                            v-if="! selectedCert && (document.filename =='additional_certificate.pdf')"
                            @click="selectFile(document.filename)"
                        >
                            <i class="fa fa-hand-pointer"></i>
                            {{ aqFiles.certificate_file ? 'Change File' : 'Select File' }}
                        </base-button>

                        <base-button
                            tag="a" size="sm"
                            type="success"
                            :href="documentUrl+qualificationId+'_'+document.filename"
                            target="_blank"
                            role="button"
                            aria-pressed="true"
                            v-if="aqFiles.certificate_file && ! selectedCert &&
                            (document.filename =='additional_certificate.pdf')"
                        >
                            <i class="fa fa-eye"></i> View File
                        </base-button>

                        <base-button
                            size="sm" type="primary"
                            v-if="! selectedRegCert && (document.filename =='registration_certificate.pdf')"
                            @click="selectFile(document.filename)"
                        >
                            <i class="fa fa-hand-pointer"></i>
                            {{ aqFiles.registration_file ? 'Change File' : 'Select File' }}
                        </base-button>

                        <base-button
                            tag="a" size="sm"
                            type="success"
                            :href="documentUrl+qualificationId+'_'+document.filename"
                            target="_blank"
                            role="button"
                            aria-pressed="true"
                            v-if="aqFiles.registration_file && ! selectedRegCert &&
                            (document.filename =='registration_certificate.pdf')"
                        >
                            <i class="fa fa-eye"></i> View File
                        </base-button>

                        <base-button
                            size="sm" type="primary"
                            v-if="! selectedNotifyCert && (document.filename =='notification_certificate.pdf')"
                            @click="selectFile(document.filename)"
                        >
                            <i class="fa fa-hand-pointer"></i>
                            {{ aqFiles.notification_file ? 'Change File' : 'Select File' }}
                        </base-button>

                        <base-button
                            tag="a" size="sm"
                            type="success"
                            :href="documentUrl+qualificationId+'_'+document.filename"
                            target="_blank"
                            role="button"
                            aria-pressed="true"
                            v-if="aqFiles.notification_file && ! selectedNotifyCert &&
                            (document.filename =='notification_certificate.pdf')"
                        >
                            <i class="fa fa-eye"></i> View File
                        </base-button>

                        <base-button
                            size="sm" type="danger"
                            v-if="(registrationFile.name == document.filename || certificateFile.name == document.filename
                                    || notificationFile.name == document.filename)
                                && (registrationFile.name || certificateFile.name || notificationFile.name)"
                            @click="removeFile(document.filename)"
                        >
                            <i class="fa fa-times"></i> Remove File
                        </base-button>
                        <br><br>

                    </div>
                    <div class="col-lg-12"><hr></div>
                </div>

                <div class="col-sm-12 text-center">
                    <base-alert :type="errorType" v-if='showInputErrorStatus'>
                        <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                        <span class="alert-inner--text">
                            {{errorMsg}}
                        </span>
                    </base-alert>
                </div>

                <div class="pl-lg-4" v-if='! showErrorStatus'>
                    <br>
                    <base-button
                        type="success"
                        @click="saveAdditionalQualifcation()"
                    >
                        <i class="fa fa-save"></i>
                        {{isloading ? 'Please Wait...' : saveButton }}
                    </base-button>
                </div>

                <div class="col-sm-12 text-center">
                    <base-alert :type="errorType" v-if='showErrorStatus'>
                        <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                        <span class="alert-inner--text">
                            {{errorMsg}}
                        </span>
                    </base-alert>
                </div>
            </div>
        </div>


        <hr class="my-4"/>

        <div class="pl-lg-4">
            <base-button
                type="danger"
                @click="showPreviousPage()"
            >
                <i class="fa fa-arrow-left"></i>
                Previous
            </base-button>
            <div class="right">
                <base-button
                    v-if="((isFullWithoutProvAQ || isFullRegWithoutProvAqDiaspora) && hasPendingAQ)"
                    type="success"
                    @click="showNextPage()"
                >
                    Continue
                    <i class="fa fa-arrow-right"></i>
                </base-button>

                <base-button
                    v-if="! isFullWithoutProvAQ && ! isFullRegWithoutProvAqDiaspora"
                    type="success"
                    @click="showNextPage()"
                >
                    SKip
                    <i class="fa fa-arrow-right"></i>
                </base-button>
            </div>
        </div>
    </div>


</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import flatPicker from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import swal from 'sweetalert2';// import sweet alert
    import Autocomplete from 'vuejs-auto-complete';

    export default {
        name: 'AddQualification',
        props: ['displayInsurance', 'displayNMALevy', 'isFullRegWithoutProvAqDiaspora'],
        components: {
            flatPicker,
            Autocomplete
        },
        data() {
            return {
                categories: [],
                qualifications: [],
                degrees: [],
                schools: [],
                saveButton: 'Add Now',
                record: {
                    country_id: null,
                    school: null,
                    category: null,
                    qualification: null,
                    degree: null,
                    qualify_date: null,
                    licencing_body: null,
                    other_school_name: null,
                    notification_date: null,
                    duration: null
                },
                certificateFile: {
                    'name' : null,
                    'file' : null
                },
                registrationFile: {
                    'name' : null,
                    'file' : null
                },
                notificationFile: {
                    'name' : null,
                    'file' : null
                },
                aqFiles: {
                    certificate_file: false,
                    registration_file: false,
                    notification_file: false
                },
                country: null,
                selectedFileName: null,
                showInputErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                selectedCert: false,
                selectedRegCert: false,
                selectedNotifyCert: false,
                showOtherInstitution: false,
                showErrorStatus: false,
                applicationId: null,
                qualificationId: null,
                isFullWithoutProvAQ: false,
                hasPendingAQ: false,
                isLoadingBlade: true,
                config: {
                    maxDate: new Date().fp_incr(1),
                    altInput: true,// eslint-disable-next-line
                    plugins: [new monthSelectPlugin({shorthand: true, dateFormat: "Y-m-d", altFormat: "M Y"})]
                }
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'getRootUrl', 'locations']),
            hasCountry() {
                let country = this.locations.countries.filter(item => item.id == this.record.country_id);
                return country ? country[0] : {};
            },
            documents() {
                return [
                    {
                        'name' : 'AQ Notification Certificate <br> (required)',
                        'filename' : 'notification_certificate.pdf'
                    },
                    {
                        'name' : 'Full Registration Certificate <br> (' + (this.isFullRegWithoutProvAqDiaspora ? 'optional' : 'required') + ')',
                        'filename' : 'registration_certificate.pdf'
                    },
                    {
                        'name' : 'Postgraduate Certificate being registered obtained (Optional)',
                        'filename' : 'additional_certificate.pdf'
                    }
                ];
            },
            documentUrl() {// get AQ document url
                let userFolder = '/user'+this.$store.state.user.userId;
                return this.getRootUrl+'/storage'+userFolder+'/documents/additional_qualification/';
            }
        },
        methods: {
            ...mapActions(['getAdditionalQulifications', 'getInstitutions', 'getUserPendingAq', 'addNewAdditionalQualification', 'getApplication', 
                        'getAssessmentInstitutions']),
            capitalizeFirstLetter(s) {
                let string = s.toLowerCase();
                return string[0].toUpperCase() + string.slice(1);
            },
            showPreviousPage() {// go to next page
                return this.displayInsurance(true, 'previous');
            },
            showNextPage() {// go to next page
                return this.displayNMALevy(true);
            },
            changeSchool(e) {// update school
                let data = e.selectedObject;
                this.showOtherInstitution = false;
                this.record.school = null;
                this.record.other_school_name = null;
                if(data.id == 0) {
                    this.showOtherInstitution = true;
                } else {
                    this.record.school = data.name;
                }
            },
            changeCountry(e) {// get exact institutions
                this.record.school = null;
                this.record.other_school_name = null;
                this.schools = [];
                this.preloader();
                this.getAssessmentInstitutions({ id : e.selectedObject.id }).then((res) => {
                    swal.close();
                    this.$refs.schoolAutocomplete.clear();
                    this.schools = res.schools;
                    let other = { id : 0, name: 'Others (Specify)'};
                    this.schools.push(other);
                });
                // this.record.country_id = e.value;
            },
            loadAddQualifications() {// load details
                this.loadAqAndInstitions();// load Aq And Institions
                let id = this.$store.state.user.userId;// get user id
                this.getUserPendingAq({'id' : id}).then((res) => {// get pending Aq
                    this.isLoadingBlade = false;
                    this.hasPendingAQ = res.app_status;
                    if(res.app_status) {// update
                        this.saveButton = 'Update Now';
                        this.updateAQRecord(res);// update AQ record                        
                        // this.getDegreesForEditing();// load degrees
                    } else {
                        this.qualifications = res.qualifications;// get qualifications
                        this.isFullWithoutProvAQ = res.isFullWithoutProvAQ;
                    }
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                });
            },
            loadAqAndInstitions() {// load Aq And Institions
                this.getAdditionalQulifications().then((res) => {// get add qual records
                    this.categories = res;
                });
            },
            updateAQRecord(data) {// update AQ Record for editing
                this.getDegreesForEditing(data.additional_qualification_config_id, data.qualifications);// load degrees
                this.qualifications = data.qualifications;// get qualifications
                this.qualificationId = data.id;
                this.record.country_id = data.country_id;
                this.record.school = data.school_name;
                this.record.category = data.qualification && data.qualification.category ? data.qualification.category.id : null;
                this.record.qualification = data.additional_qualification_config_id;
                this.record.degree = (data.degree ? data.degree : null);
                this.record.qualify_date = data.qualify_date;
                this.record.licencing_body = data.licencing_body;
                this.record.notification_date = data.notification_date;
                this.record.duration = data.duration;
                this.aqFiles.certificate_file = data.files.certificate_file;
                this.aqFiles.registration_file = data.files.registration_file;
                this.aqFiles.notification_file = data.files.notification_file;
                this.applicationId = data.application.id;                
                this.isFullWithoutProvAQ = data.isFullWithoutProvAQ;
                // this.hasPendingAQ = data.app_status;
                if(data.country && data.country.schools) {// update schools
                    this.schools = data.country.schools;
                    let other = { id : 0, name: 'Others (Specify)'};
                    this.schools.push(other);
                }  
            },
            getDegreesForEditing(qualId = null, quals = null) {// get Qualification's degrees
                let degrees = [];
                let id = qualId ? qualId : this.record.qualification;
                let qualifications = quals ? quals : this.qualifications;
                for(let qualification of qualifications) {
                    if(qualification.id == id) {
                        degrees = qualification.qualification.split(',');
                    }
                }
                this.degrees = degrees;
            },
            getDegrees() {// get Qualification's degrees
                this.degrees = [];
                this.record.degree = null;
                for(let qualification of this.qualifications) {
                    if(qualification.id == this.record.qualification) {
                        this.degrees = qualification.qualification.split(',');
                    }
                }
            },
            selectFile(name) {// eslint-disable-next-line
                $('#file').val('');
                this.selectedFileName = null;// eslint-disable-next-line
                $('#file').click();
                this.selectedFileName = name;
                if(name == 'additional_certificate.pdf') {
                    this.certificateFile.name = name;
                } else if(name == 'registration_certificate.pdf') {
                    this.registrationFile.name = name;
                } else {
                    this.notificationFile.name = name;
                }
            },
            onFileSelected(event) {
                let status =  this.validateFile(event.target.files[0]);
                var fileReader = new FileReader();
                fileReader.readAsDataURL(event.target.files[0]);
                fileReader.onload = (event) => {// convert file to binary
                    var file = event.target.result;
                    if(this.selectedFileName == 'additional_certificate.pdf') {
                        this.certificateFile.file = file;
                        this.selectedFileName = null;
                    } else if(this.selectedFileName == 'registration_certificate.pdf') {
                        this.registrationFile.file = file;
                        this.selectedFileName = null;
                    } else if(this.selectedFileName == 'notification_certificate.pdf'){
                        this.notificationFile.file = file;
                        this.selectedFileName = null;
                    }

                }
                return status;
            },
            validateFile(file) {// check for valid file
                if(!file) {// update seleted file name
                    if(this.selectedFileName == 'additional_certificate.pdf') {
                        this.certificateFile.name = null
                    } else if(this.selectedFileName == 'registration_certificate.pdf') {
                        this.registrationFile.name = null;
                    } else {
                        this.notificationFile.name = null;
                    }
                }
                var fileExt = file.type.split('/').pop();
                let fileSize = file.size;
                if(fileExt == 'pdf' && fileSize <= 500000) {// for valid file and size
                    if(this.selectedFileName == 'additional_certificate.pdf') {
                        this.selectedCert = true
                    } else if(this.selectedFileName == 'registration_certificate.pdf') {
                        this.selectedRegCert = true;
                    } else {
                        this.selectedNotifyCert = true;
                    }
                    return true;
                } else if(fileExt != 'pdf') {// invalid file
                    this.removeAllFiles();
                    swal.fire("Invalid File!", "Only PDF files are accepted", "warning");
                    return false;
                } else if(fileSize > 500000) {// big file
                    this.removeAllFiles();
                    swal.fire("File is too large!", "File should be less than 500KB", "warning");
                    return false;
                }
            },
            removeAllFiles() {// eslint-disable-next-line
                $('#file').val('');
                this.certificateFile.name = null;
                this.certificateFile.savedEvent = null;
                this.certificateFile.file = null;
                this.selectedCert = false;
                this.registrationFile.name = null;
                this.registrationFile.savedEvent = null;
                this.registrationFile.file = null;
                this.selectedRegCert = false;
                this.notificationFile.name = null;
                this.notificationFile.savedEvent = null;
                this.notificationFile.file = null;
                this.selectedNotifyCert = false;
            },
            removeFile(name) {// eslint-disable-next-line
                $('#file').val('');
                if(name == 'additional_certificate.pdf') {
                    this.certificateFile.name = null;
                    this.certificateFile.savedEvent = null;
                    this.certificateFile.file = null;
                    this.selectedCert = false;
                } else if(name == 'registration_certificate.pdf') {
                    this.registrationFile.name = null;
                    this.registrationFile.savedEvent = null;
                    this.registrationFile.file = null;
                    this.selectedRegCert = false;
                } else {
                    this.notificationFile.name = null;
                    this.notificationFile.savedEvent = null;
                    this.notificationFile.file = null;
                    this.selectedNotifyCert = false;
                }
            },
            saveAdditionalQualifcation() {// save add qual
                let data = this.validatedInputs();// validate inputs
                if(data) {// update files
                    this.isloading = true;
                    data.certificate_file = this.certificateFile;
                    data.registration_file = this.registrationFile;
                    data.notification_file = this.notificationFile;
                    data.degree = (this.record.degree ? this.record.degree : null);// get degree
                    data.user_data_id = this.$store.state.user.userDataId;// get user id
                    data.user_id = this.$store.state.user.userId;// get user id
                    data.code = 'additional-pgq';
                    data.id = this.applicationId;// get id
                    if(this.applicationId) {
                        data.option = 'update';
                    } else {
                        data.option = 'save';
                        data.trans_no = this.genRandomString();// generate trans no
                    }
                    // console.log(data)
                    this.addNewAdditionalQualification(data).then((res) => {// add AQ
                        this.isloading = false;
                        this.emptyFileName();
                        if(res.status) {
                            this.loadAddQualifications();// load add qual records
                            swal.fire("Record Updated!", "Record was updated successfully", "success");
                        } else {
                            this.loadAddQualifications();// load add qual records
                            swal.fire("Error Occurred!", "An error occurred while saving, try again", "warning");
                        }
                    }).
                    catch(err => {
                        this.isloading = false;
                        this.emptyFileName();
                        this.loadAddQualifications();// load add qual records
                        swal.fire(err.message+"!", "An error occurred while saving, try again", "warning");
                    });
                }
            },
            emptyFileName() {
                this.certificateFile.name = null;
                this.selectedFileName = null;
                this.registrationFile.name = null;
                this.selectedCert = false;
                this.selectedRegCert = false;
            },
            genRandomString() {
                var length = 12;
                var text = '';
                var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
                for (var i=0; i < length; i++) {
                    text += possible.charAt(Math.floor(Math.random() * possible.length));
                }
                return text;
            },
            validatedInputs() {
                let data = this.record;
                let certFile = null;
                let regFile = null;
                let addRecord = { country_id: null, school_name: null, qualification: null, qualify_date: null, licencing_body: null, duration: null, 
                                    notification_date: null };
                regFile = (this.registrationFile.name == null && ! this.aqFiles.registration_file && ! this.isFullRegWithoutProvAqDiaspora) ?
                    this.showInuptError('Full Registration Certificate File') : true;
                certFile = (this.notificationFile.name == null && ! this.aqFiles.notification_file) ?
                    this.showInuptError('Notification Certificate File') : true;
                addRecord.notification_date  = data.notification_date == null ? this.showInuptError('Notification Date') : data.notification_date;
                addRecord.duration  = data.duration == null ? this.showInuptError('Duration of Training') : data.duration;
                addRecord.licencing_body  = data.licencing_body == null ? this.showInuptError('Issuing Institution') : data.licencing_body;
                addRecord.qualify_date  = data.qualify_date == null ? this.showInuptError('Qualification Date') : data.qualify_date;
                addRecord.qualification  = data.qualification == null ? this.showInuptError('Qualification') : data.qualification;
                // addRecord.category = data.category == null ?
                //     this.showInuptError('Category') : data.category;
                // validate institution
                if(data.school == null && data.other_school_name != null) {
                    addRecord.school_name = data.other_school_name;
                } else if(data.school != null) {
                    addRecord.school_name = data.school;
                } else if(data.other_school_name != null) {
                    addRecord.school_name = data.other_school_name;
                } else {
                    this.showInuptError('School\'s Name');
                }
                addRecord.country_id  = data.country_id == null ? this.showInuptError('Country') : data.country_id;
                return this.isEmpty(addRecord) && certFile && (regFile || this.isFullRegWithoutProvAqDiaspora) ? addRecord : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b> ...',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadAddQualifications();// load add qual records
        },
    }
</script>