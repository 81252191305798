<template>
    <form>
        <h6 class="heading-small text-muted mb-4">
            <span class="text-danger">
                <i class="fa fa-info-circle"></i> Apply for an application below
            </span>
        </h6>

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div
            class="container ct-example-row"
            v-if="applications && applications.length > 0 && ! isLoadingBlade"
        >
            <div class="row">
                <div
                    class="col-sm-4 text-center borderLine"
                    v-for="application in applications"
                    :key="application.id"
                    v-show="application.application_type"
                >
                    <br>
                    <h4 class="text-capitalize height-50"> 
                        {{ application.application_type.name }}
                    </h4><br>
                    <router-link
                        :to="'/new-application/'+application.application_type.code"
                        class="btn btn-sm btn-success"
                        v-show="(application.application_type.code != 'life-license') && ! checkIfCanApplyFullReg(application.application_type.code)"
                    >
                        <i class="fa fa-share-square"></i> Apply Now
                    </router-link>

                    <router-link
                        :to="'/new-application/'+application.application_type.code"
                        class="btn btn-sm btn-success"
                        v-if="application.application_type.code == 'life-license'"
                        v-show="displayLifeLicense(application.application_type.code)"
                    >
                        <i class="fa fa-share-square"></i> Apply Now
                    </router-link>

                    <badge
                        type="danger"
                        class="text-wrap"
                         v-if="application.application_type.code == 'life-license' &&
                                ! displayLifeLicense(application.application_type.code)"
                    >
                        Will be available when you are up to 70 years old.
                    </badge>

                    <badge
                        type="danger"
                        class="text-wrap"
                         v-if="checkIfCanApplyFullReg(application.application_type.code) && (hideFullRegText != '')"
                    >
                        <h5 v-html="hideFullRegText"></h5>
                    </badge>
                    <br><br>
                </div>
            </div>
        </div>

        <!-- Application Procedure Notice template here -->
        <div class="col-md-4" :show="procedureModal">
            <modal :show="procedureModal"
                    gradient="warning"
                    modal-classes="modal-warning modal-dialog-centered">
                <h4
                    slot="header" class="modal-title"
                    id="modal-title-notification"
                >
                    Importance Notice
                </h4>

                <div class="py-3 text-center">
                    <!-- <i class="ni ni-bell-55 ni-3x"></i> -->
                    <h4 class="heading mt-4">Simple Application Procedure!</h4>
                    <p>
                        1. Click on the desired Application<br>
                        2. Fill the necessary information as required, click to proceed<br>
                        3. At Payment page, you can either Pay Online, generate RRR and pay through Remita designated banks or
                            Add RRR that has been paid for already<br>
                        4. After succesful Payment, your Application will be submitted for review and approval by MDCN
                    </p>
                </div>

                <template slot="footer">
                    <base-button type="link"
                                text-color="white"
                                class="ml-auto"
                                @click="procedureModal = false">
                        Close
                    </base-button>
                </template>
            </modal>
        </div>

        <div class="col-sm-12 text-center">
            <base-alert :type="errorType" v-if='showErrorStatus'>
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>
    </form>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import moment from 'moment';

    export default {
        name: 'NewApplications',
        data() {
            return {
                applications: [],
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                isLoadingBlade: true,
                procedureModal: true,
                hideFullRegButton: false,
                hideFullRegText: ''
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl'])
        },
        methods: {
            ...mapActions(['getNewApplications', 'internshipStatus']),
            loadNewApplications() {
                const userId = this.$store.state.user.userId;
                this.getNewApplications({'id' : userId}).then((res) => {// get edu records
                    this.applications = res;
                    this.isLoadingBlade = false;
                    this.emptyRecordsMsg();//show empty msg
                }).
                catch(() => {
                    this.showErrorStatus = true;
                    this.errorMsg = "Network Failure!", "Kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            checkInternshipStatus() {// check internship status
                this.hideFullRegButton = true;
                this.hideFullRegText = ''
                this.internshipStatus().then((res) => {
                    if(! res.status) { 
                        this.hideFullRegButton = true; 
                        this.hideFullRegText = res.msg;
                    } else {
                        this.hideFullRegButton = false; 
                    }
                }).
                catch(() => {
                    this.hideFullRegButton = false;
                    this.hideFullRegText = '';
                });
            },
            emptyRecordsMsg() {
                return this.applications && this.applications.length == 0
                    ? this.showEmptyMsg() : false;
            },
            showEmptyMsg() {// show empty msg
                this.showErrorStatus = true;
                this.errorType = 'warning';
                this.errorMsg = 'No New Applications Yet!!!';
            },
            displayLifeLicense() {// show apply now when is 70 years
                let dob = this.$store.state.user.basicProfile.date_of_birth;
                let years = parseInt(moment(dob).fromNow(true));
                return years >= 70;
            },
            checkIfCanApplyFullReg(code) {// if user can apply for full registration
                let checkAppType = ((code == 'full-reg') || (code == 'full-reg-upgrade'));
                return (this.hideFullRegButton && checkAppType)
            }
        },
        created() {
            this.loadNewApplications();// load new applications
            this.checkInternshipStatus();
        },
    }
</script>

<style scoped>
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>