<template>
    <div>
        <div v-if="! isLoadingBlade && validLicenseYear && canApplyForRetention">
            <div class="row">
                <div class="col-sm-12 text-center">
                    <br>
                    <h3 class="text-danger">
                        Note: You are seeing this payment because you are practicing abroad.
                         If you want to make payment for previous year licenses before the year
                        <em class="text-primary">{{ defaultYear }}</em>, kindly click on the
                        <em class="text-primary">Previous Licenses Purchase</em> button above.
                    </h3>

                    <hr class="my-4"/>
                    <br>
                    <h3>
                        {{ application.application_type.name }}
                        <br><br>
                        <span>License Year: </span> &nbsp;
                        <em class="font-weight-light">
                            {{ application.year }}
                        </em>
                        <br><br>
                        <span>Amount: </span> &nbsp;
                        <em class="font-weight-light">
                            {{ parseInt(application.application_type.has_payment.amount) | formatPrice }}
                        </em>

                        <span v-if="application.surcharge">
                            <br><br>
                            <span class="text-danger">Surcharge: </span> &nbsp;
                            <em class="font-weight-light text-danger">
                                {{ parseInt(application.application_type.has_payment.surcharge) | formatPrice }}
                            </em><br>
                            <em class="font-weight-light text-danger">
                                (It is only applicable to late payment)
                            </em>
                        </span>

                        <br><br>
                        <span>Total Amount: </span> &nbsp;
                        <em class="font-weight-light">
                            {{ parseInt(totalAmount) | formatPrice }}
                        </em>
                    </h3>
                </div>

                <div class="col-sm-12 text-center">
                    <hr>
                    <router-link
                        :to="'/new-application/'+application.application_type.code"
                        class="btn btn-lg btn-success"
                    >
                        <i class="fa fa-share-square"></i> Proceed Now
                    </router-link>
                </div>
            </div>

            <div class="col-sm-12 text-center">
                <base-alert :type="errorType" v-if='showErrorStatus'>
                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                    <span class="alert-inner--text">
                        {{errorMsg}}
                    </span>
                </base-alert>
            </div>
        </div>

        <div class="col-sm-12 text-center" v-if="! validLicenseYear && ! isLoadingBlade && canApplyForRetention">
            <h2 class="text-danger">
                Your License is up-to-date, kindly wait for the 
                <em class="text-primary">1st of January of {{ maximunYear }}</em>, <br>
                to purchase License for 
                <em class="text-primary">{{ maximunYear + 1 }}</em>
                
            </h2>
            <br><br>
            <router-link
                to="/"
                class="btn btn-lg btn-primary"
            >
                <i class="fa fa-home"></i>
                Dashboard
            </router-link>
        </div>

        <div class="col-sm-12 text-center" v-if="! isLoadingBlade && ! canApplyForRetention">
            <h2 class="text-danger">
                You are not Fully Registered with the Council. If you have a pending Full Registration application, kindly wait for approval by the Council. 
                Otherwise, to apply for Full Registration below...               
                <!-- <router-link to="/applications" class="text-primary">My Applications</router-link> menu and click on 
                <em class="text-primary">New Application</em> tab.                 -->
            </h2> 

            <FullWithoutProvPage 
                :applicationTypes="applicationTypes"
            />
            
        </div>

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>
    </div>
</template>
<script>
    import moment from 'moment'
    import { mapGetters, mapActions } from 'vuex'
    import FullWithoutProvPage from './FullWithoutProvPage.vue'
    
    export default {
        name: 'DiasporaLicense',
        props: ['year', 'hasApprovedFullReg', 'hasFullRegCertificate'],
        components: {
            FullWithoutProvPage
        },
        data() {
            return {
                application: null,
                isLoadingBlade: true,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                defaultYear: 2024
            }
        },
        filters: {
            formatPrice(value) {
                if(value) {
                    let val = (value/1).toFixed(2).replace(',', '.');
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' USD';
                } else {
                    return 'N/A';
                }
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl']),
            validLicenseYear() {
                return this.application && this.application.purchase_status;
            },
            maximunYear() {// get maixmum year for license purchase
                return (moment().year() + 1);
            },
            totalAmount() {// get total amount 
                let appPay = this.application.application_type.has_payment;
                return this.application.surcharge ? parseInt(appPay.amount) + parseInt(appPay.surcharge) : appPay.amount;
            },
            canApplyForRetention() {
                return this.hasApprovedFullReg || this.hasFullRegCertificate;
            },
            applicationTypes() {
                return this.application ? this.application.application_types : [];
            }
        },        
        methods: {
            ...mapActions(['getDiasporaLicense']),
            loadDoctorDiasporaLicense() {// get doctor diaspora license details
                // const year = this.year ? this.year : 2024;// default set to 2024
                this.getDiasporaLicense().then((res) => {// get record
                    this.isLoadingBlade = false;
                    this.application = res.application;
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.showErrorStatus = true;
                    this.errorMsg = "Network Failure!, kindly reload the page...";
                    this.errorType = 'danger';
                });
            }
        },
        created() {
            this.loadDoctorDiasporaLicense();// get doctor diaspora license details
        },
    }
</script>