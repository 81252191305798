<template>
    <div>
        <div slot="header" class="border-0">
            <div class="row align-items-center" v-if="application">
                <div class="col-8">
                    <h4 class="mb-0 text-uppercase">
                        Make Payment for
                        <em class="text-danger">{{ application.name }}</em>
                    </h4>
                </div>
                <div class="col-4 text-right">
                    <router-link to="/applications" class="btn btn-sm btn-danger">
                        <i class="fa fa-reply"></i> Back
                    </router-link>
                </div>
            </div>
        </div>

        <div class="container ct-example-row">
            <div class="row" v-if="application && application.has_payment">
                <div class="col-lg-12">
                    <ul class="list-unstyled">
                        <li>
                            <hr class="my-4">
                            <h3 class="text-center">
                                Application Name: &nbsp;
                                <em class="text-capitalize font-weight-light">
                                    {{ application.name }}
                                </em>
                            </h3>
                            <br>
                            <h3 class="text-center">
                                Total Amount: &nbsp;
                                <em class="text-capitalize font-weight-light">
                                    {{ application.has_payment.amount | formatPrice }} {{ currency }}
                                </em>
                            </h3>

                            <div v-if="application.has_payment.has_breakdowns.length > 0">
                                <hr class="my-4">
                                <h1 class="text-center">
                                    Payment Details
                                </h1>
                                <hr class="my-4">
                                <h3
                                    v-for="breakdown in application.has_payment.has_breakdowns"
                                    :key="breakdown.id"
                                >
                                    {{ breakdown.name }}: &nbsp;
                                    <em class="text-capitalize font-weight-light right">
                                        {{ breakdown.amount | formatPrice }} {{ currency }}
                                    </em>
                                    <br>
                                </h3>

                                <h3 v-if="surcharge">
                                    Surcharge: &nbsp;
                                    <em class="text-capitalize font-weight-light right">
                                        {{ application.has_payment.surcharge | formatPrice }} {{ currency }}
                                    </em>
                                    <br>
                                </h3>
                            </div>

                            <hr class="my-4">
                            <h3>
                                Total: &nbsp;
                                <em class="text-capitalize font-weight-light right">
                                    {{ totalAmount | formatPrice }} {{ currency }}
                                </em>
                            </h3>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-12 text-center">
                    <hr class="my-4">

                    <flutterwave-pay-button
                        :tx_ref="orderId"
                        :amount="totalAmount"
                        :currency="currency"
                        payment_options="card"
                        redirect_url=""
                        class="btn btn-success"
                        style=""
                        :meta="paymentRecords.meta"
                        :customer="paymentRecords.customer"
                        :customizations="paymentRecords.customizations"
                        :callback="makePaymentCallback"
                        :onclose="closedPaymentModal"
                        v-if="showPaymentForm"
                    >
                        <i class="fa fa-credit-card"></i>                            
                        {{ isloading ? 'Please Wait...' : 'Make Payment Now'}}
                    </flutterwave-pay-button>


                    <base-button
                        type="success"
                        @click="updateApplicationTransNumber()"
                        v-if="! showPaymentForm"
                    >
                        <i class="fa fa-save"></i>
                        {{ isloading ? 'Please Wait...' : 'Apply Now'}}
                    </base-button>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import swal from 'sweetalert2';// import sweet alert
    import moment from 'moment';
    import { FlutterwavePayButton } from "flutterwave-vue-v3";

    export default {
        components: {
            FlutterwavePayButton
        },
        name: 'DiasporaLicensePayment',
        data() {
            return {
                id: this.$route.params.id,
                application: null,
                isLoadingBlade: true,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                applicationId: null,
                orderId: null,
                serviceType: null,
                user: {},
                orderNumber: null,
                showPaymentForm: false,
                isDirectPaymentPage: false,
                showGeneratePage: false,
                surcharge: false
            }
        },
        filters: {
            formatPrice(value) {
                if(value) {
                    let val = (value/1).toFixed(2).replace(',', '.');
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                } else {
                    return 'N/A';
                }
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl']),
            currency() {// get application currency
                return this.application && this.application.has_payment && this.application.has_payment.currency 
                            ? this.application.has_payment.currency  : 'NGN';
            },
            basicProfile() {// get user data
                return this.user && this.user.basic_profile ? this.user.basic_profile : {}
            },
            fullName() {// get user's full name
                let name = '';
                if(this.basicProfile) {
                    name += this.basicProfile.first_name;
                    name += this.basicProfile.other_name ? ' ' +  this.basicProfile.other_name : '';
                    name += ' ' + this.basicProfile.last_name;
                }
               return name;
            },
            paymentRecords() {
                return {
                    meta : {
                        counsumer_id: (this.basicProfile ? this.basicProfile.id : ''),
                        // consumer_mac: "kjs9s8ss7dd"
                    },
                    customer: {
                        name: this.fullName,
                        email: (this.user ? this.user.email : ''),
                        phone_number: (this.basicProfile ? this.basicProfile.phone : '')
                    },
                    customizations: {
                        title: "MDCN Global Network",
                        description: "Global Network",
                        logo: "https://www.portal.mdcn.gov.ng/img/brand/white.png"
                    }
                };                
            },
            totalAmount() {// get total amount 
                let appPay = this.application.has_payment;
                return this.surcharge ? parseInt(appPay.amount) + parseInt(appPay.surcharge) : appPay.amount;
            }
        },
        methods: {
            ...mapActions(['updateDiasporaAppTransaction', 'getUserApplicationDetails', 'updateTransNo']),
            makePaymentCallback(res) {
                if(res.status == "successful") {// payment was successful
                    this.preloader(); // loading....
                    this.isloading = true;
                    let data = {
                        orderId : this.orderId,
                        status : res.charge_response_code,
                        amount : res.amount,
                        transactiontime : res.created_at,
                        paymentDate : res.created_at,
                        message : res.charge_response_message,
                        RRR : res.transaction_id,
                        currency : this.currency
                    };   
                    this.updateDiasporaAppTransaction(data).then((res) => {// create application
                        this.loadApplication();
                        this.isloading = false;
                        if(res.status) {// update transaction                            
                            swal.fire('Payment Successful!', res.msg, 'success');
                        } else {
                            swal.fire('Error Occurred', res.msg, 'warning');
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        let message = 'An error occurred while adding payment, kindly requery transaction before making another payment';
                        swal.fire('Error Occurred', message, 'warning');
                        return false;
                    });
                } else {
                    swal.fire('Payment Failed!', 'Payment wasn\'t succesfull, kindly try again...', 'warning');
                }
            },
            closedPaymentModal() {
                // console.log('payment modal is closed');
            },
            loadApplication() {// get application
                this.getUserApplicationDetails(this.id).then((res) => {// get application
                    this.setData(res);// set data
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.showErrorStatus = true;
                    this.errorMsg = "Network Failure!", "Kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            setData(res) {
                const currentYear = moment().year() + 1;// get current license year
                const record = res.previous_applications && res.previous_applications.length > 0 ? res.previous_applications[0] : false;
                if(! record) {// redirect back to license
                    return window.location.href = '/applications';
                }
                this.application = res.application;
                this.serviceType = res.service_type;
                this.user = res.user;
                this.applicationId = record.id;
                this.orderId = record.trans_no;
                this.surcharge = (parseInt(record.license_year) < currentYear);
                this.isLoadingBlade = false;
            },
            updateApplicationTransNumber() {// get application details
                if(this.applicationId) {
                    this.preloader();// show loading
                    this.updateTransNo({id : this.applicationId}).then((res) => {//update trans no
                        this.orderId = res.trans_no;
                        this.showPaymentForm = true;
                        swal.close();
                    }).
                    catch(() => {
                        let message = 'Network Failure!, kindly reload the page...';
                        swal.fire('Network Failure!', message, 'warning');
                    });
                }
                
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadApplication();// load application
        },
    }
</script>

<style scoped>
.right {
    float: right;
}
</style>