<template>
    <div
            class="container text-center"
    >
        <hr>
        <div class="row">
            <div class="col-lg-2"></div>
            <div
                class="col-lg-4 text-center borderLine"
                v-for="application in applicationTypes"
                :key="application.id"
            >
                <br>
                <h4 class="text-capitalize height-50"> 
                    {{ application.name }}
                </h4><br>
                <router-link
                    :to="'/new-application/'+application.code"
                    class="btn btn-sm btn-success"
                >
                    <i class="fa fa-share-square"></i> Apply Now
                </router-link>
                <br><br>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FullWithoutProvPage',
        props: ['applicationTypes']
    }
</script>

<style scoped>
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>