<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>


        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase">My License</h3>
                                </div>
                                <div class="col-4 text-right">
                                    <router-link to="/" class="btn btn-sm btn-primary">Dashboard</router-link>
                                </div>
                            </div>
                        </div>

                        <template>
                            <tabs fill class="flex-column flex-md-row">
                                <card shadow>
                                    <tab-pane title="Purchase License">
                                        <span slot="title">
                                            <i class="ni ni-spaceship"></i>
                                            Purchase License
                                        </span>
                                        <!-- import User Purchase License template here -->
                                        <PurchaseLicense
                                            :isTempReg="isTempReg"
                                            :getTempRegUrl="getTempRegUrl"
                                            :isDiasporaDoctor="isDiasporaDoctor"
                                            :hasApprovedFullReg="hasApprovedFullReg"
                                            :hasFullRegCertificate="hasFullRegCertificate"
                                        />
                                    </tab-pane>

                                    <tab-pane
                                        title="CPD Points"
                                        v-if="! hideCpdPoint"
                                    >
                                        <span slot="title">
                                            <i class="ni ni-books"></i>
                                            CPD Points
                                        </span>
                                        <!-- import CPD Points template here -->
                                        <CpdPoints />
                                    </tab-pane>

                                    <tab-pane
                                        title="Previous Licenses Purchase"
                                        v-if="! isTempReg && ! isDiasporaDoctor"
                                    >
                                        <span slot="title">
                                            <i class="ni ni-spaceship"></i>
                                            Previous Licenses Purchase
                                        </span>
                                        <!-- import User Previous License template here -->
                                        <PreviousLicense />
                                    </tab-pane>

                                </card>
                            </tabs>

                        </template>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CpdPoints from './CpdPoints.vue';
    import PurchaseLicense from './PurchaseLicense.vue';
    import PreviousLicense from './PreviousLicense';
import { mapGetters } from 'vuex';
import { mapActions } from 'vuex/dist/vuex.common.js';

    export default {
        components: {
            CpdPoints,
            PurchaseLicense,
            PreviousLicense
        },
        name: 'License',
        computed: {
            ...mapGetters(['user']),
            profile() {// get doctor basic profile
                return this.$store.state.user.basicProfile;
            },
            subregTypeCode() {// get doctor sub registration type code
                return this.$store.state.user.basicProfile.has_sub_registration_type;
            },
            isProvisionalRegistration() {
                return this.profile && this.subregTypeCode && this.subregTypeCode.registration_type.code == 'provisional-reg' ? true : false;
            },
            isTempReg() {
                return this.profile && this.subregTypeCode && this.subregTypeCode.registration_type.code == 'temporary-reg' ? true : false;
            },
            hideCpdPoint() {
                return this.isProvisionalRegistration || this.isTempReg || this.isDiasporaDoctor;
            },
            getTempRegUrl() {// get exact temp renewal url
                let code = this.subregTypeCode ? this.subregTypeCode.code : false;
                return this.isTempReg && code ? '/renew-temp/'+ code + '-renewal' : '/';
            },
            isFullReg() {// if doctor is fully registered 
                return this.profile && this.subregTypeCode && ((this.subregTypeCode.registration_type.code == 'full-reg') || 
                        (this.subregTypeCode.registration_type.code == 'full-without-provisional-reg')) ? true : false;
            },
            hasApprovedFullReg() {
                return this.profile && this.profile.has_approved_full_reg ? this.profile.has_approved_full_reg : false;
            },
            hasFullRegCertificate() {
                return this.user && this.user.full_reg_status ? (this.user.full_reg_status == 'yes') : false;
            },
            isDiasporaDoctor() {// the fully registered doctor practice abroad 
                const diasporaStatus = this.user && this.user.diaspora_status ? (this.user.diaspora_status == 'yes') : false;
                return diasporaStatus && this.isFullReg;
            }
        },
        methods: {
            ...mapActions(['fetchUserProfile'])
        },
        created() {
            this.fetchUserProfile();
        }
    }
</script>