<template>
    <div class="row justify-content-center">
        <div class="col-lg-12 col-md-7">
            <div class="card bg-secondary shadow border-0">
                <div class="card-body px-lg-5 py-lg-5">

                    <div class="row">
                        <div
                            class="col-sm-6 text-center borderLine"
                        >
                            <br>
                            <h1 class="text-capitalize height-50">
                                New Doctor Account
                            </h1>

                            <h3 class="text-danger">
                                Note: All Doctors (except those applying for Temporary/Limited Application) should click on the button below
                            </h3><br><br>

                            <router-link
                                to="/doctor-register"
                                class="btn btn-lg btn-success"
                            >
                                Click Here
                            </router-link>
                            <br>
                        </div>

                        <div
                            class="col-sm-6 text-center borderLine"
                        >
                            <br>
                            <h1 class="text-capitalize height-50">
                                New Temporary/Limited Doctor Account
                            </h1><br>

                            <h3 class="text-danger">
                                Note: This registration is strictly for those applying for Temporary/Limited Application
                            </h3><br>
                            <router-link
                                to="/temp-doctor-register"
                                class="btn btn-lg btn-success"
                            >
                                Click Here
                            </router-link>
                            <br>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'register',
    }
</script>

<style scoped>
    .height-50 {
        height: 50px;
    }
    .borderLine {
        /* border: 2px solid #ededed; */
        background-color: #f7fafc !important;
    }
    .margin20 {
        margin-bottom: 20px;
    }
</style>

<style>
    .bg-default {
        background-color: #f5365c !important;
    }
    .bg-gradient-danger {
        background: linear-gradient(87deg, #f5365c 0, #f56036 70%) !important
         /* linear-gradient(100deg, #f7fafc 0, #96e7c4 300%) !important */
    }
    .fill-default {
        fill: #f5365c;
    }
    .navbar-horizontal .navbar-brand img {
        height: 110px;
    }
    .navbar-dark .navbar-nav .nav-link {
        color: rgb(50, 50, 93);
    }
</style>
