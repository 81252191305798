<template>
    <div class="col-lg-12">

        <AssociationPage 
            :associations="associations"
            :record="record"
            :saveUserAssociation="saveUserAssociation"
            :hasAssociation="hasAssociation"
            :isToVerifyMembership="isToVerifyMembership"
            :isloading="isloading"
            v-on:verifyMembership="verifyMembership"
            v-if="! isLoadingBlade"
        /> 

        <div class="col-lg-12"><hr></div>

        <div class="col-lg-12">
            <base-button
                type="danger"
                @click="showPreviousPage()"
            >
                <i class="fa fa-arrow-left"></i>
                Previous
            </base-button>
        </div>

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import swal from 'sweetalert2';// import sweet alert
    import axios from 'axios';
    import AssociationPage from './AssociationPage.vue'

    export default {
        components: {
            AssociationPage           
        },
        name: 'AbroadAssociationPage',
        props: ['displayNMALevy', 'user', 'hasAssociation', 'associations', 'isToVerifyMembership'],
        data() {
            return {
                record: {
                    association_id: null,
                    email: null,
                },                
                isloading: false,
                showContinue: false,
                isLoadingBlade: false
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl'])          
        },
        methods: {
            ...mapActions(['updateUserForeignAssociation', 'fetchUserProfile']),
            showPreviousPage() {// go to next page
                return this.displayNMALevy(true);
            },
            setData() {
                let id = this.user && this.user.basic_profile ? this.user && this.user.basic_profile.association_id : null;
                return this.record.association_id = id;
            },
            saveUserAssociation(e) {// update user's foreign association
                this.isLoadingBlade = true;
                if(e.value) {
                    let data = { association_id : e.value };
                    this.updateUserForeignAssociation(data).then((res) => {
                        this.fetchUserProfile();// load bio data
                        this.isLoadingBlade = false;
                        if(res.status) {// load updated
                            this.saveButton = true;
                            swal.fire("Record Updated!", res.msg, "success");
                        } else {
                            swal.fire("Error Occurred", res.msg, "warning");
                        }
                    });
                } else {
                    this.isLoadingBlade = false;
                    swal.fire("Empty Fields!", "Kindly select your Association", "warning");
                }
            },
            verifyMembership() {// verify membership function   allthenebors@gmail.com      dangeorge35@gmail.com
                let url = this.hasAssociation ? this.hasAssociation.url : false;
                if(url && this.record.email) {
                    url += this.record.email;
                    this.isloading = true;
                    axios.get(url).then((res) => {// api call
                        if(res.status == 200) {
                            let response = JSON.parse(res.data.replace('Array', ''));
                            if(response.status == 'success') {// member verified 
                                let data =  { association_status : 'verified' };
                                this.updateUserForeignAssociation(data).then((res) => {
                                    this.fetchUserProfile();// load bio data
                                    this.isloading = false;
                                    if(res.status) {// load updated
                                        this.saveButton = true;
                                        swal.fire("Verification Successful!", 'Your membership was verified successfully', "success");
                                    } else {
                                        const msg = 'Your membership verification failed, kindly proceed to your association portal for clearance';
                                        swal.fire("Verification Failed", msg, "warning");
                                    }
                                });
                            } else {
                                this.isloading = false;
                                const msg = 'Your membership verification failed, kindly proceed to your association\'s portal for clearance';
                                swal.fire("Verification Failed", msg, "warning");
                            }
                        }
                    })
                    .catch(() => {//check for failure
                        this.isloading = false;
                        swal.fire("Error Occurred", 'Error occurred while processing, try again', "warning");
                    });
                } else {
                    swal.fire("Empty Field!", "Kindly enter a valid email address", "warning");
                }
            }
        },
        created() {
            this.setData();
        }
    }
</script>

<style scoped>
.right {
    float: right;
}
</style>