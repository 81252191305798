import { render, staticRenderFns } from "./DiasporaRegistrationPayment.vue?vue&type=template&id=376d75fd&scoped=true&"
import script from "./DiasporaRegistrationPayment.vue?vue&type=script&lang=js&"
export * from "./DiasporaRegistrationPayment.vue?vue&type=script&lang=js&"
import style0 from "./DiasporaRegistrationPayment.vue?vue&type=style&index=0&id=376d75fd&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "376d75fd",
  null
  
)

export default component.exports