<template>
    <div>
        <div class="row" v-if="canApplyForRetention && ! showGuidelinePage"> 

            <div class="col-lg-12" v-if="isLoadingBlade">
                <div class="d-flex justify-content-center">
                    <img :src="loadingBladeUrl" /> 
                </div>
            </div> 

            <div class="col-lg-12" v-if='! showErrorStatus && ! isLoadingBlade'>
                <h3 class="heading text-muted mb-4">
                    <span class="text-danger">
                        {{ applicationType.name }} Application for <em class="text-primary">{{ application.year }}</em>
                    </span><br>
                    <small><em>Upload proof of License to practice in the foreign country</em></small>            
                </h3><hr>
            </div>

            <!-- this is association verification page -->
            <div class="col-lg-12" v-if="! isDiasporaApplicationPaid && ! isLoadingBlade && ! associationIsVerified">
                <AssociationPage 
                    :associations="associations"
                    :record="record"
                    :saveUserAssociation="updateUserAssociation"
                    :hasAssociation="hasAssociation"
                    :isToVerifyMembership="isToVerifyMembership"
                    :isloading="isloading"
                    v-on:verifyMembership="verifyMembership"                               
                />
                <hr>
            </div>

            <div class="col-lg-12 text-center" 
                v-if='! showErrorStatus && associationIsVerified && ! isDiasporaApplicationRejected && ! isDiasporaApplicationPaid && ! isLoadingBlade'
            >
                <h3 class="heading text-muted mb-4" v-if="application && application.license">
                    <span class="text-primary">
                        APPLICATION STATUS: &nbsp;<badge :type="getStatusColor(application.license.status)">{{ application.license.status }}</badge>
                    </span><hr>
                    <span class="text-primary" v-if="(application.license.status == 'rejected')">
                        REJECT REASON: &nbsp;<span class="text-danger">{{ application.license.reject_reason }}</span>
                    </span><hr v-if="(application.license.status == 'rejected')">
                    <small 
                        class="text-danger"
                        v-if="((application.license.status != 'rejected') && (application.license.status != 'approved'))"
                    >
                        You can only proceed to make payment after approval, so kindly wait for approval by MDCN
                    </small> 
                    
                    <small 
                        class="text-danger"
                        v-if="(application.license.status == 'approved')"
                    >
                        You can now proceed to make payment...
                    </small> 

                    <small 
                        class="text-danger"
                        v-if="(application.license.status == 'rejected')"
                    >
                        Your Application was rejected, kindly see reason and modify accordingly, then submit for approval by MDCN <br>
                        <!-- Or Proceed to pay for normal annual license below -->
                    </small>       
                </h3>
                <hr>
            </div>

            <!-- if application is rejected blade -->
            <div class="col-lg-12 text-center" 
                v-if='! showErrorStatus && associationIsVerified && isDiasporaApplicationRejected && ! isDiasporaApplicationPaid && ! isLoadingBlade'
            >
                <h3 class="heading text-muted mb-4" v-if="application">
                    <span class="text-primary">
                        APPLICATION STATUS: &nbsp;<badge type="danger">Rejected</badge>
                    </span><hr>

                    <span class="text-primary">
                        REJECT REASON: &nbsp;<span class="text-danger">{{ diasporaApplication.reject_reason }}</span>
                    </span><hr>

                    <small class="text-danger">
                        Your {{ applicationType.name }} Application for <em class="text-primary">{{ application.year }}</em> 
                        was rejected. Kindly see reason and modify accordingly, then submit for approval by MDCN <br>
                    </small>       
                </h3>
                <hr>  
            </div>

            <!-- this upload document page -->
            <DiasporaDocument 
                :documents="documents"
                :selectedFileName="selectedFileName"
                :loadPercent="loadPercent"
                :showReSendButton="showReSendButton"
                :onFileSelected="onFileSelected"
                :showUploadButton="showUploadButton"
                :isLicensePending="isLicensePending"
                v-on:selectFile="selectFile"
                v-on:removeFile="removeFile"
                v-on:backToAssocaition="backToAssocaition"
                v-if='! showErrorStatus && associationIsVerified && ! isDiasporaApplicationPaid && ! isLoadingBlade'
            />


            <div class="col-lg-12 text-center" v-if='showInputErrorStatus && ! isDiasporaApplicationPaid && ! isLoadingBlade'>
                <base-alert :type="errorType">
                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                    <span class="alert-inner--text">
                        {{errorMsg}}
                    </span>
                </base-alert>
            </div>  


            <div class="col-lg-12" v-if='! showErrorStatus && associationIsVerified && ! isDiasporaApplicationPaid && ! isLoadingBlade'>
                <hr>
                <base-button 
                    type="success"
                    @click="saveDiasporaLicense()"
                    :disabled="! uploadedFile.name"
                    v-if="! isLicenseSubmitted && ! isRejectedLicense"
                >
                    <i class="fa fa-save"></i> 
                    {{isloading ? 'Please Wait...' : 'Apply Now'}}
                </base-button>

                <div class="right" v-if="isRejectedLicense || isDiasporaApplicationRejected">
                    <base-button
                        type="success"
                        @click="reSendApplication(application, isRejectedLicense)"
                        :disabled="! uploadedFile.name"
                    >
                        <i class="fa fa-upload"></i>
                        {{isloading ? 'Please Wait...' : 'Re-send Application'}}
                    </base-button>
                </div>  

                <!-- <div class="right" v-if="showReSubmitButton && application && application.license && application.license.user_application">
                    <base-button
                        type="success"
                        @click="reSendApplication(application.license.user_application.id)"
                        :disabled="! uploadedFile.name"
                    >
                        <i class="fa fa-upload"></i>
                        {{isloading ? 'Please Wait...' : 'Re-send Application'}}
                    </base-button>
                </div>             -->

                <!-- <div class="right" v-if="isRejectedLicense">
                    <router-link
                        :to="'/new-application-payment/'+application.annual_license_id"
                        class="btn btn-info"
                    >
                        <i class="fa fa-share-square"></i> Purchase Annual License
                    </router-link>
                </div> --> 

                <div class="right" v-if="showPaymentButton">
                    <flutterwave-pay-button
                        :tx_ref="orderId"
                        :amount="totalAmount"
                        :currency="currency"
                        payment_options="card"
                        redirect_url=""
                        class="btn btn-success"
                        style=""
                        :meta="paymentRecords.meta"
                        :customer="paymentRecords.customer"
                        :customizations="paymentRecords.customizations"
                        :callback="makePaymentCallback"
                        :onclose="closedPaymentModal"
                    >
                        <i class="fa fa-credit-card"></i>                            
                        {{ isloading ? 'Please Wait...' : 'Make Payment Now'}}
                    </flutterwave-pay-button>
                </div>
            </div>    

            <div class="col-sm-12 text-center" v-if='showErrorStatus && ! isLoadingBlade'>
                <base-alert :type="errorType">
                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                    <span class="alert-inner--text">
                        {{errorMsg}}
                    </span>
                </base-alert>
            </div>

            <div class="col-lg-12 text-center" v-if='! showErrorStatus && ! isLoadingBlade && isDiasporaApplicationPaid'>
                <h3 class="heading text-muted mb-4" v-if="application">
                    <span class="text-primary">
                        APPLICATION STATUS: &nbsp;<badge type="warning">Submitted</badge>
                    </span><hr>

                    <small class="text-danger">
                        Your {{ applicationType.name }} Application for <em class="text-primary">{{ application.year }}</em> 
                        has been submitted, kindly wait for approval by MDCN <br>
                    </small>       
                </h3>
                <hr>        
            </div>

        </div>

        <div class="col-sm-12 text-center" v-if="! canApplyForRetention && ! showGuidelinePage && ! isLoadingBlade">
            <h2 class="text-danger">
                You are not Fully Registered with the Council. If you have a pending Full Registration application, kindly wait for approval by the Council. 
                Otherwise, to apply for Full Registration, go to                
                <router-link to="/applications" class="text-primary">My Applications</router-link> menu and click on 
                <em class="text-primary">New Application</em> tab.                
            </h2> 
            <br><br>
            <router-link
                to="/"
                class="btn btn-lg btn-primary"
            >
                <i class="fa fa-home"></i>
                Dashboard
            </router-link>
        </div>

        <DiasporaGuidelines 
            :hideGuidelines="hideGuidelines"
            v-if="showGuidelinePage && ! isLoadingBlade"
        />
    </div>
    
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import swal from 'sweetalert2';// import sweet alert
    import moment from 'moment';
    import DiasporaDocument from './DiasporaDocument.vue'
    import AssociationPage from '../../../NewAccount/AbroadAssociationPage/AssociationPage.vue'
    import axios from 'axios';
    import { FlutterwavePayButton } from "flutterwave-vue-v3";
    import DiasporaGuidelines from './DiasporaGuidelines.vue';

    export default {
        name: 'ApplyDiasporaLicense',
        props: ['applicationType', 'type'],
        components: {
            DiasporaDocument,
            AssociationPage,
            FlutterwavePayButton,
            DiasporaGuidelines
        },
        data() {
            return {
                id: this.$route.params.id,
                application: null,
                record: {
                    id: null,
                    user_data_id: null,
                    user_application_id: null,
                    year: null,
                    application_type_id: null,
                    trans_no: null,
                    application_date: null,
                    association_id: null,
                    association_status: 'not-verified',
                    email: null
                },
                showUploadButton: false,
                savedEvent: null,
                uploadedFile: {
                    name : null,
                    file : null
                },
                selectedFileName: null,
                loadPercent: {
                    type: 'warning',
                    value: 10,
                    animated: true,
                    show: false
                },
                documents: [],
                showErrorStatus: false,
                showInputErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                isLoadingBlade: true,
                // showReSendButton: false,
                applicationId: null,
                associations: [],
                showGuidelinePage: true
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'user']),
            hasApprovedFullReg() {// check if user has approved full registration
                return this.application ? this.application.has_full_reg : false;
            },
            hasFullRegCertificate() {
                return this.user && this.user.full_reg_status ? (this.user.full_reg_status == 'yes') : false;
            },
            canApplyForRetention() {
                return this.hasApprovedFullReg || this.hasFullRegCertificate;
            },
            diasporaApplication() {
                return this.application && this.application.license && this.application.license.user_application 
                                ? this.application.license.user_application : false;
            },
            isDiasporaApplicationPaid() {// check if the application has been paid
                return (this.diasporaApplication.payment_status == 'paid') &&  (this.diasporaApplication.status == 'pending');
            },
            isDiasporaApplicationRejected() {// check if the application has been paid
                return (this.diasporaApplication.payment_status == 'paid') &&  (this.diasporaApplication.status == 'rejected');
            },
            orderId() {
                return this.application && this.application.license && this.application.license.user_application ?
                        this.application.license.user_application.trans_no : null;
            },
            currency() {// get application currency
                return this.application && this.application.application_type && this.application.application_type.has_payment 
                        && this.application.application_type.has_payment.currency ? this.application.application_type.has_payment.currency  : 'NGN';
            },
            totalAmount() {// get total amount
                let surcharge = this.application ? this.application.surcharge : false;
                let payment = this.application && this.application.application_type ? this.application.application_type.has_payment : false;
                let amount = payment ? parseInt(payment.amount) : false;
                return surcharge ? parseInt(amount + parseInt(payment.surcharge)) : parseInt(amount);
            },
            showReSendButton() {// enable upload button
                return (this.application && this.application.license && ((this.application.license.status == 'rejected') 
                        || ((this.application.license.status == 'pending')))) || ! this.application.license || this.isDiasporaApplicationRejected;
            },
            showPaymentButton() {// display make payment button
                return this.application && this.application.license  && (this.application.license.status == 'approved')
                            && (this.application.license.payment_status == 'not-paid');
            },
            isRejectedLicense() {// is rejected diaspora license
                return this.application && this.application.license && (this.application.license.status == 'rejected') 
                        && (this.application.license.payment_status == 'not-paid');
            },
            showReSubmitButton() {
                return this.application && this.application.license && (this.application.license.status == 'rejected') 
                        && (this.application.license.payment_status == 'paid'); 
            },
            hasAssociation() {
                let association = this.associations.filter(item => item.id == this.record.association_id);
                return association ? association[0] : null;
            },
            isToVerifyMembership() {
                return this.hasAssociation ? this.hasAssociation && (this.hasAssociation.status == 'verify') : false;
            },
            associationIsVerified() {// user association has been verified
                let AssocationStatus =  this.record && this.record.association_status ? (this.record.association_status == 'verified') : false;
                return this.record.association_id && (! this.isToVerifyMembership || (this.isToVerifyMembership && AssocationStatus)) ? true : false;
            },
            isLicenseSubmitted() {// is diaspora license submitted
                return this.application && this.application.license ? (this.application.license.status == 'submitted') : false;
            },
            isLicensePending() {// is diaspora license submitted
                return this.application && this.application.license ? (this.application.license.status == 'pending') : false;
            },
            basicProfile() {// get user data
                return this.user && this.user.basic_profile ? this.user.basic_profile : {}
            },
            fullName() {// get user's full name
                let name = '';
                if(this.basicProfile) {
                    name += this.basicProfile.first_name;
                    name += this.basicProfile.other_name ? ' ' +  this.basicProfile.other_name : '';
                    name += ' ' + this.basicProfile.last_name;
                }
               return name;
            },
            paymentRecords() {
                return {
                    meta : {
                        counsumer_id: (this.basicProfile ? this.basicProfile.id : ''),
                        // consumer_mac: "kjs9s8ss7dd"
                    },
                    customer: {
                        name: this.fullName,
                        email: (this.user ? this.user.email : ''),
                        phone_number: (this.basicProfile ? this.basicProfile.phone : '')
                    },
                    customizations: {
                        title: "MDCN Global Network",
                        description: "Global Network",
                        logo: "https://www.portal.mdcn.gov.ng/img/brand/white.png"
                    }
                };                
            }   
        },
        methods: {
            ...mapActions(['getDiasporaLicense', 'createDiasporaLicense', 'reSubmitDiasoraApplication', 'updateDiasporaAppTransaction']),
            hideGuidelines() {
                return this.showGuidelinePage = false;
            },
            makePaymentCallback(res) {
                if(res.status == "successful") {// payment was successful
                    this.preloader(); // loading....
                    this.isloading = true;
                    let data = {
                        orderId : this.orderId,
                        status : res.charge_response_code,
                        amount : res.amount,
                        transactiontime : res.created_at,
                        paymentDate : res.created_at,
                        message : res.charge_response_message,
                        RRR : res.transaction_id,
                        currency : this.currency
                    };   
                    this.updateDiasporaAppTransaction(data).then((res) => {// create application
                        this.loadApplication();
                        this.isloading = false;
                        if(res.status) {// update transaction                            
                            swal.fire('Payment Successful!', res.msg, 'success');
                        } else {
                            swal.fire('Error Occurred', res.msg, 'warning');
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        let message = 'An error occurred while adding payment, kindly requery transaction before making another payment';
                        swal.fire('Error Occurred', message, 'warning');
                        return false;
                    });
                } else {
                    swal.fire('Payment Failed!', 'Payment wasn\'t succesfull, kindly try again...', 'warning');
                }
            },
            closedPaymentModal() {
                // console.log('payment modal is closed');
            },
            backToAssocaition() {
                this.record.association_id = null;
                this.record.email = null;
            },
            updateUserAssociation(e) {// update user's foreign association
                return this.record.association_id = e.value;
            },
            verifyMembership() {// verify membership function   allthenebors@gmail.com      dangeorge35@gmail.com
                let url = this.hasAssociation ? this.hasAssociation.url : false;
                if(url && this.record.email) {
                    url += this.record.email;
                    this.isloading = true;
                    axios.get(url).then((res) => {// api call
                        if(res.status == 200) {
                            let response = JSON.parse(res.data.replace('Array', ''));
                            if(response.status == 'success') {// member verified 
                                this.isloading = false;
                                this.record.association_status = 'verified';
                                swal.fire("Verification Successful!", 'Your membership was verified successfully', "success");
                            } else {
                                this.isloading = false;
                                const msg = 'Your membership verification failed, kindly proceed to your association\'s portal for clearance';
                                swal.fire("Verification Failed", msg, "warning");
                            }
                        }
                    })
                    .catch(() => {//check for failure
                        this.isloading = false;
                        swal.fire("Error Occurred", 'Error occurred while processing, try again', "warning");
                    });
                } else {
                    swal.fire("Empty Field!", "Kindly enter a valid email address", "warning");
                }
            },
            loadApplication() {// get doctor diaspora license details
                // const year = this.year ? this.year : 2024;// default set to 2024
                this.getDiasporaLicense({license_id : this.id, type : this.type}).then((res) => {// get record
                    this.setData(res);// set data 
                    this.isLoadingBlade = false;
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.showErrorStatus = true;
                    this.errorMsg = "Network Failure!, kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            setData(response) {
                const res = response.application;
                this.application = res;
                this.documents = res ? res.documents : [];// get documents
                this.associations = response.associations;// set foreign associations
                this.record = {
                    id: (res.license ? res.license.id : null),
                    user_data_id: (res.license ? res.license.user_data_id : this.$store.state.user.userDataId),
                    user_application_id: (res.license ? res.license.user_application_id : null),
                    year: (res.license ? res.license.year : res.year),
                    application_type_id: (res.application_type && res.application_type.id ? res.application_type.id : false),
                    trans_no: (res.license && res.license.user_application && res.license.user_application.trans_no 
                                    ? res.license.user_application.trans_no  : this.genRandomString()),
                    application_date: (res.license && res.license.user_application && res.license.user_application.application_date 
                                    ? res.license.user_application.application_date  : moment().format('YYYY-MM-DD')),
                    association_id: (res.license && res.license.association_id ? res.license.association_id : null),
                    association_status: (res.license && res.license.association_status ? res.license.association_status : 'not-verified'),
                    email: null
                };
                this.isLoadingBlade = false;
                this.showGuidelinePage = res && ! res.license ? true : false;
            },
            selectFile(name) {// eslint-disable-next-line
                if(this.showReSendButton) {
                    $('#file').val('');
                    this.selectedFileName = null;
                    this.showUploadButton = false;// eslint-disable-next-line
                    $('#file').click();
                    this.uploadedFile.name = name;// add file new name
                    this.selectedFileName = name;
                }                
            },
            onFileSelected(event) {
                this.savedEvent = event;
                let status =  this.validateFile(event.target.files[0]);
                status ? this.showUploadButton = true : false;
                return status;
            },
            validateFile(file) {// check for valid file
                if(!file) {// update seleted file name
                    this.uploadedFile.name = null;
                    this.selectedFileName = null;
                }
                var fileExt = file.type.split('/').pop();
                let fileSize = file.size;
                if(fileExt == 'pdf' && fileSize <= 500000) {// for valid file and size
                    return true;
                } else if(fileExt != 'pdf') {// invalid file
                    this.uploadedFile.name = null;
                    this.selectedFileName = null;
                    swal.fire("Invalid File!", "Only PDF files are accepted", "warning");
                    return false;
                } else if(fileSize > 300000) {// big file
                    this.uploadedFile.name = null;
                    this.selectedFileName = null;
                    swal.fire("File is too large!", "File should not be more than 500KB", "warning");
                    return false;
                }
            },
            saveDiasporaLicense() {// upload document 
                if(this.uploadedFile.name) {
                    this.loadPercent.show = true;
                    let event = this.savedEvent;// get store file event
                    var fileReader = new FileReader();                
                    fileReader.readAsDataURL(event.target.files[0]);
                    fileReader.onload = (event) => {// convert file to binary
                        this.uploadedFile.file = event.target.result;
                        this.createDiasporaLicense({record: this.record, file: this.uploadedFile}).then((res) => {
                            this.uploadPercent(this.$store.state.application.uploadProgress);// show load progress
                            var self = this;                        
                            const title = res.status ? "Apllication Submitted!" : "Error Occurred";
                            swal.fire(title, res.msg, "success");
                            setTimeout(function() { self.resetRecords(self);}, 3000);
                            self.loadApplication();// refresh page
                        }).
                        catch(err => {
                            swal.fire(err.message+"!", "Reduce file size and try again...", "warning");
                            this.resetRecords(this);
                            this.loadApplication();// refresh page
                        });
                    }
                }                
            },
            resetRecords(self) {// reset records
                self.loadPercent.show = false;
                self.loadPercent.type = 'warning';
                self.showUploadButton = false;
                self.loadPercent.value = 10;
                self.uploadedFile.name = null;
                self.selectedFileName = null;
            },
            uploadPercent(percent) {// update upload progress percent
                this.loadPercent.value = percent;
                this.loadPercent.type = percent > 60 ? 'success' : 'warning';
            },
            removeFile() {// eslint-disable-next-line
                $('#file').val('');
                this.selectedFileName = null;
                this.uploadedFile.name = null;
                this.uploadedFile.file = null;
                this.showUploadButton = false;
                this.savedEvent = null;
            },
            genRandomString() {
                var length = 12;
                var text = '';
                var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
                for (var i=0; i < length; i++) {
                    text += possible.charAt(Math.floor(Math.random() * possible.length));
                }
                return text;
            },
            getStatusColor(status) {
                if(status == 'approved') {
                    return 'success';
                } else if(status == 'submitted') {
                    return 'warning';
                } else {
                    return 'danger';
                }
            },
            reSendApplication(record, status) {// re-send rejected application
                swal.fire({
                    title: 'Are you sure?',
                    text: "You must have corrected the issue before proceeding to re-submit application!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, I have corrected it!'
                }).then((result) => {
                    if (result.value) {
                        this.makeReSubmitApiCall(record, status);// make api call
                    }
                });
            },
            makeReSubmitApiCall(record, status) {
                this.preloader(); // show loading
                let data = { status : 'submitted', reject_reason : null, approved_date : null };
                if(status) {// for the document
                    data.option = 'license';
                    data.id = record.license ? record.license.id : null;
                    data.status = 'submitted';
                } else {// for the application
                    data.option = 'application';
                    data.id = record.license ? record.license.user_application_id : null;
                    data.status = 'pending';
                }
                this.reSubmitDiasoraApplication(data).then((res) => {// get application record
                    if(res.status) {
                        this.resetRecords(this);
                        this.loadApplication();// load application
                        swal.fire("Application Submitted!", res.msg, "success");
                    } else {
                        swal.fire("Error Occurred!", res.msg, "warning");
                    }
                }).
                catch(() => {
                    let msg = "Server Failure! , kindly reload the page...";
                    swal.fire("Server Failure!", msg, "warning");
                });
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b> ...',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadApplication();// load records
        },
    }
</script>

<style scoped>
    .right {
        float: right;
    }
</style>