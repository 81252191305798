<template>
    <form>
        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div v-if="! isLoadingBlade">
            <h3 class="heading-small text-danger mb-4">
                Note: Only Provisional Doctors applying for Full Registration can fill out this form, while those practising abroad and Others can skip below.
                <br><br>
                <span class="text-danger">
                    <i class="fa fa-info-circle"></i> Only PDF Files (Not More than 800KB) are supported for each file
                </span>
            </h3>

            <div class="container ct-example-row">
                <!-- import Internship Document File template here -->
                <InternshipDocument
                    v-bind:documents="documents"
                    v-bind:id="record.id"
                    v-bind:loadInternship="loadInternship"
                    type="upgrade"
                />
            </div>

            <br>
            <div class="row align-items-center">
                <div class="col-lg-5">
                    <base-input
                        label="Your Internship Performance Start Date (Date you started your internship program)"
                        addon-left-icon="ni ni-calendar-grid-58"
                        required

                    >
                        <flat-picker
                            slot-scope="{focus, blur}"
                            @on-open="focus"
                            @on-close="blur"
                            :config="{allowInput: true}"
                            class="form-control datepicker"
                            v-model="record.start_date"
                        >
                        </flat-picker>
                    </base-input>
                </div>
                <div class="col-lg-6">
                    <base-input
                        label="Internship Your Performance End Date (Date you completed/ you ended your internship program)"
                        addon-left-icon="ni ni-calendar-grid-58"
                        required

                    >
                        <flat-picker
                            slot-scope="{focus, blur}"
                            @on-open="focus"
                            @on-close="blur"
                            :config="{allowInput: true}"
                            class="form-control datepicker"
                            v-model="record.end_date"
                        >
                        </flat-picker>
                    </base-input>
                </div>
            </div>

            <div class="col-sm-12 text-center">
                <base-alert :type="errorType" v-if='showErrorStatus'>
                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                    <span class="alert-inner--text">
                        {{errorMsg}}
                    </span>
                </base-alert>
            </div>

            <div class="col-lg-12"></div>
            <div class="col-lg-12">
                <base-button
                    type="success"
                    @click="updateRecords()"
                    :disabled="isloading"
                >
                    <i class="fa fa-save"></i>
                    {{isloading ? 'Please Wait...' : 'Update Record'}}
                </base-button>
            </div>
        </div>

        <hr class="my-4"/>

        <div class="pl-lg-4">
            <base-button
                type="danger"
                @click="showPreviousPage()"
            >
                <i class="fa fa-arrow-left"></i>
                Previous
            </base-button>
            <div class="right">
                <base-button
                    type="success"
                    @click="showNextPage()"
                >
                    <!-- v-if="(isFullWithoutProvIntern || isFullReg) && hasInternReport" -->
                    Proceed / Skip
                    <i class="fa fa-arrow-right"></i>
                </base-button>

                <!-- <base-button
                    type="success"
                    v-if="(! isFullWithoutProvIntern && ! isFullReg) || isOldDoctor"
                    @click="showNextPage()"
                >
                    Skip
                    <i class="fa fa-arrow-right"></i>
                </base-button> -->
            </div>
        </div>

    </form>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import InternshipDocument from '../ProfessionalData/Internship/InternshipDocument.vue';
    import flatPicker from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import moment from 'moment';

    export default {
        components: {
            InternshipDocument,
            flatPicker
        },
        name: 'Internship',
        props: ['displayDocuments', 'displayInsurance'],
        data() {
            return {
                record: {
                    start_date: null,
                    end_date: null,
                    id: null
                },
                documents: [],
                isloading: false,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isFullWithoutProvIntern: false,
                isFullReg: false,
                isLoadingBlade: true
            };
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'user']),
            hasInternReport() {
                let status = [];
                let checker = arr => arr.every(v => v === true);
                for(let doc of this.documents) {
                    status.push(doc.hasFile);
                }
                return checker(status) && this.record.start_date != null
                    && this.record.end_date != null;
            },
            isOldDoctor() {// check if user is old doctor
                let year = this.user && this.user.basic_profile ? this.user.basic_profile.year_of_graduation : false;
                return (moment().diff(year, 'years') > 10);
            }
        },
        methods: {
            ...mapActions(['getUserInternshipRecords', 'updateInternship']),
            showPreviousPage() {// go to next page
                return this.displayDocuments(true);
            },
            showNextPage() {// go to next page
                return this.displayInsurance(true);
            },
            loadInternship() {
                const id = this.$store.state.user.userDataId;// get user data Id
                this.getUserInternshipRecords({'id' : id}).then((res) => {
                    this.isLoadingBlade = false;
                    this.documents = res.documents;
                    this.record.start_date = res.start_date;
                    this.record.end_date = res.end_date;
                    this.record.id = this.$store.state.user.userId;// get user Id
                    this.isFullWithoutProvIntern = res.isFullWithoutProvIntern;
                    this.isFullReg = res.isFullReg;
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.showErrorStatus = true;
                    this.errorMsg = "Network Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            updateRecords() {
                this.isloading = true;
                let data = this.validatedInputs();// validate inputs
                if(data) {// save edu records
                    data.id = this.$store.state.user.userDataId;// get user data Id
                    this.updateInternship(data).then((res) => {
                        this.isloading = false;
                        this.loadInternship();
                        if(res.status) {
                            let msg = 'Internship Records updated successfully';
                            this.showMsg(msg, 'success');
                        } else {
                            this.showErrorMsg();
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        this.loadInternship();
                        this.showErrorMsg();
                    });
                } else {
                    this.isloading = false;
                    this.loadInternship();
                }
            },
            validatedInputs() {
                let data = this.record;
                let internRecord = { start_date: null, end_date: null };
                internRecord.end_date = data.end_date == null ?
                    this.showInuptError('End Date') : data.end_date;
                internRecord.start_date = data.start_date == null ?
                    this.showInuptError('Start Date') : data.start_date;
                return this.isEmpty(internRecord) ? internRecord : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showErrorMsg() {
                this.showErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'An error occurred, kindly reload page and try again';
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
            showMsg(msg, type) {
                this.showErrorStatus = true;
                this.errorType = type;
                this.errorMsg = msg;
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
        },
        created() {
            this.loadInternship();// load Internship
        },
    }
</script>

<style>

</style>
