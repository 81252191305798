var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-header',{staticClass:"header pb-8 pt-5 pt-lg-8 d-flex align-items-center"},[_c('span',{staticClass:"mask bg-gradient-danger opacity-8"})]),_c('div',{staticClass:"container-fluid mt--7"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12 order-xl-1"},[_c('card',{attrs:{"shadow":"","type":"secondary"}},[_c('div',{staticClass:"bg-white border-0",attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-12"},[_c('h3',{staticClass:"mb-0 text-uppercase"},[_vm._v(_vm._s(_vm.title))]),_c('br'),_c('span',{staticClass:"text-danger"},[_vm._v(" Note that you have to provide correct information, so that your account can be updated. Also check the spelling of your names and make sure they are correct. ")])])])]),(_vm.showBioData)?_c('BioData',{attrs:{"displayEduRecords":_vm.displayEduRecords}}):_vm._e(),(! _vm.showBioData && _vm.showEduRecords)?_c('EduRecords',{attrs:{"displayBioData":_vm.displayBioData,"displaySpecData":_vm.displaySpecData}}):_vm._e(),(! _vm.showBioData && ! _vm.showEduRecords && _vm.showSpecData)?_c('Specialization',{attrs:{"displayEduRecords":_vm.displayEduRecords,"displayAppointments":_vm.displayAppointments}}):_vm._e(),(! _vm.showBioData && ! _vm.showEduRecords && ! _vm.showSpecData
                            && _vm.showAppointments)?_c('Appointments',{attrs:{"displaySpecData":_vm.displaySpecData,"displayPhoto":_vm.displayPhoto}}):_vm._e(),(! _vm.showBioData && ! _vm.showEduRecords && ! _vm.showSpecData
                            && ! _vm.showAppointments && _vm.showPhoto)?_c('ProfilePhoto',{attrs:{"displayAppointments":_vm.displayAppointments,"displayDocuments":_vm.displayDocuments}}):_vm._e(),(! _vm.showBioData && ! _vm.showEduRecords && ! _vm.showSpecData
                            && ! _vm.showAppointments && ! _vm.showPhoto && _vm.showDocuments)?_c('Documents',{attrs:{"displayPhoto":_vm.displayPhoto,"displayInternship":_vm.displayInternship}}):_vm._e(),(! _vm.showBioData && ! _vm.showEduRecords && ! _vm.showSpecData
                            && ! _vm.showAppointments && ! _vm.showPhoto && ! _vm.showDocuments
                            && _vm.showInternship)?_c('Internship',{attrs:{"displayDocuments":_vm.displayDocuments,"displayInsurance":_vm.displayInsurance}}):_vm._e(),(! _vm.showBioData && ! _vm.showEduRecords && ! _vm.showSpecData
                            && ! _vm.showAppointments && ! _vm.showPhoto && ! _vm.showDocuments
                            && ! _vm.showInternship && _vm.showInsurance)?_c('Insurance',{attrs:{"displayInternship":_vm.displayInternship,"displayAddQual":_vm.displayAddQual}}):_vm._e(),(! _vm.showBioData && ! _vm.showEduRecords && ! _vm.showSpecData
                            && ! _vm.showAppointments && ! _vm.showPhoto && ! _vm.showDocuments
                            && ! _vm.showInternship && ! _vm.showInsurance && _vm.showAddQual)?_c('AddQualification',{attrs:{"locations":_vm.locations,"displayInsurance":_vm.displayInsurance,"displayNMALevy":_vm.displayReg,"isFullRegWithoutProvAqDiaspora":_vm.isFullRegWithoutProvAqDiaspora}}):_vm._e(),(! _vm.showBioData && ! _vm.showEduRecords && ! _vm.showSpecData
                            && ! _vm.showAppointments && ! _vm.showPhoto && ! _vm.showDocuments
                            && ! _vm.showInternship && ! _vm.showInsurance && ! _vm.showAddQual
                            && ! _vm.showAddRecords && _vm.showNMALevy)?_c('NMALevy',{attrs:{"displayAddQual":_vm.displayAddQual,"displayReg":_vm.displayReg}}):_vm._e(),(! _vm.showBioData && ! _vm.showEduRecords && ! _vm.showSpecData
                            && ! _vm.showAppointments && ! _vm.showPhoto && ! _vm.showDocuments
                            && ! _vm.showInsurance && ! _vm.showInternship && ! _vm.showAddQual
                            && ! _vm.showAddRecords && ! _vm.showNMALevy && _vm.showReg)?_c('Registration',{attrs:{"displayNMALevy":_vm.displayAddQual,"isFullRegWithoutProvAqDiaspora":_vm.isFullRegWithoutProvAqDiaspora,"isFullRegWithoutProvInternDiaspora":_vm.isFullRegWithoutProvInternDiaspora}}):_vm._e()],1)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }