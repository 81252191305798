var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-header',{staticClass:"header pb-8 pt-5 pt-lg-8 d-flex align-items-center"},[_c('span',{staticClass:"mask bg-gradient-danger opacity-8"})]),_c('div',{staticClass:"container-fluid mt--7"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12 order-xl-1"},[_c('card',{attrs:{"shadow":"","type":"secondary"}},[_c('div',{staticClass:"bg-white border-0",attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-8"},[_c('h5',{staticClass:"mb-0 text-uppercase"},[_vm._v(" Pending Application for "),(_vm.application && _vm.application.application_type)?_c('em',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.application.application_type.name)+" ")]):_vm._e()])]),_c('div',{staticClass:"col-4 text-right"},[_c('router-link',{staticClass:"btn btn-sm btn-danger",attrs:{"to":"/applications"}},[_c('i',{staticClass:"fa fa-reply"}),_vm._v(" Back ")])],1)])]),(_vm.isLoadingBlade)?_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('img',{attrs:{"src":_vm.loadingBladeUrl}})])]):_vm._e(),(_vm.application && _vm.application.application_type && ((_vm.application.application_type.code == 'additional-pgq') 
                                || (_vm.application.application_type.code == 'additional-pgq-diaspora')))?_c('ApplyAddQualification'):_vm._e(),(_vm.application && _vm.application.application_type && ((_vm.application.application_type.code == 'change-of-name') 
                                || (_vm.application.application_type.code == 'change-of-name-diaspora')))?_c('ApplyChangeName'):_vm._e(),(_vm.application && _vm.application.application_type &&
                            _vm.application.application_type.code == 'reval-reg')?_c('ApplyRevalidation'):_vm._e(),(_vm.application && _vm.application.application_type &&
                            _vm.application.application_type.code == 'cert-good-standing')?_c('Logs'):_vm._e(),(_vm.application && _vm.application.application_type &&
                            _vm.application.application_type.code == 'eics-verification')?_c('ApplyEics'):_vm._e(),(_vm.application && _vm.application.application_type &&
                            _vm.application.application_type.code == 'life-license')?_c('ApplyLifeLicense',{attrs:{"applicationType":_vm.application.application_type}}):_vm._e(),(_vm.application && _vm.application.application_type &&
                            _vm.application.application_type.code == 'ctc-full-reg')?_c('ApplyCtcFullReg'):_vm._e(),(_vm.application && _vm.application.application_type &&
                            _vm.application.application_type.code == 'full-intern-reg')?_c('ApplyFullWithoutIntern'):_vm._e(),(_vm.application && _vm.application.application_type &&
                            _vm.application.application_type.code == 'full-aq-reg')?_c('ApplyFullWithoutAq',{attrs:{"applicationType":_vm.application.application_type}}):_vm._e(),(_vm.application && _vm.application.application_type &&
                            ((_vm.application.application_type.code == 'limited-reg') ||
                            (_vm.application.application_type.code == 'limited-reg-long')
                            || (_vm.application.application_type.code == 'limited-reg-renewal')
                            || (_vm.application.application_type.code == 'limited-reg-long-renewal')))?_c('PendingTempReg',{attrs:{"applicationType":_vm.application.application_type}}):_vm._e(),(_vm.application && _vm.application.application_type &&
                            _vm.application.application_type.code == 'diaspora-license')?_c('ApplyDiasporaLicense',{attrs:{"applicationType":_vm.application.application_type,"type":'pending-app'}}):_vm._e(),(_vm.showErrorStatus)?_c('div',{staticClass:"col-sm-12 text-center"},[_c('base-alert',{attrs:{"type":_vm.errorType}},[_c('span',{staticClass:"alert-inner--icon"},[_c('i',{staticClass:"ni ni-like-2"})]),_c('span',{staticClass:"alert-inner--text"},[_vm._v(" "+_vm._s(_vm.errorMsg)+" ")])])],1):_vm._e()],1)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }