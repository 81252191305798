<template>
    <div class="pl-lg-4">

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <!-- <h3 class="heading text-muted mb-4"  v-if="! isLoadingBlade">
            Update Educational Records
        </h3> -->

        <em class="mb-4 text-danger"  v-if="! isLoadingBlade">
            Kindly fill your medical school details first, before updating
            other educational records.
        </em>


        <hr class="my-4"  v-if="! isLoadingBlade"/>

        <!-- import edu records details template here -->
        <EduRecordsData
            v-bind:eduRecords="eduRecords"
            v-bind:showAddRecord="showAddRecord"
            v-on:deleteEduRecord="deleteEduRecord"
            v-bind:pagination="pagination"
            v-bind:hasPagination="hasPagination"
            v-bind:paginate="paginate"
            :type="true"
        />

        <hr class="my-4" v-if="hasRecords && ! showAddRecord"/>

        <div class="pl-lg-4" v-if="hasRecords && ! showAddRecord">
            <base-button
                type="primary"
                @click="displayAddPage()"
            >
                <i class="fa fa-plus"></i>
                Add New Record
            </base-button>
        </div>

        <div
            class="row align-items-center"
            v-if="! isLoadingBlade && (! hasMedicalSchool || showAddRecord)"
        >

            <div class="col-lg-4">
                <base-input alternative=""
                    label="School Type"
                    required
                >
                    <select
                        class="form-control form-control-alternative"
                        v-model="records.school_type"
                    >
                        <option value="primary">Primary</option>
                        <option value="secondary">Secondary</option>
                        <option value="tertiary">Tertiary</option>
                        <option value="medical-school">Medical School</option>
                        <option value="pre-clinical-period">Pre-Clinical Period</option>
                        <option value="clinical-period">Clinical Period</option>
                    </select>
                </base-input>
            </div>
            <div class="col-lg-4">
                <base-input alternative=""
                    label="Country"
                    required
                >
                    <autocomplete
                        input-class="form-control form-control-alternative"
                        placeholder="Type to Search Country"
                        showNoResults
                        :source="locations.countries"
                        v-model="records.country_id"
                        @selected="changeCountry"
                    >
                    </autocomplete>
                </base-input>
            </div>
            <div class="col-lg-4" v-if="! isOtherSchool">
                <base-input alternative=""
                    label="Name of Institution"
                    required
                >
                    <autocomplete
                        ref="schoolAutocomplete"
                        input-class="form-control form-control-alternative"
                        placeholder="Type to search Institution"
                        showNoResults
                        :disableInput="institutions && institutions.length == 0"
                        :source="institutions"
                        resultsValue="name"
                        @selected="updateSchool"
                    >
                    </autocomplete>
                </base-input>
            </div>
            <div class="col-lg-4" v-if="showOtherInstitution || isOtherSchool">
                <base-input alternative=""
                    :label="(isOtherSchool ? 'Institution Name' : 'Other Institution Name')"
                    placeholder="Enter Institution Name"
                    input-classes="form-control-alternative"
                    v-model="records.other_school_name"
                    required
                />
            </div>
            <div class="col-lg-4">
                <base-input alternative=""
                    label="Qualifications Obtained"
                    required
                >
                    <select
                        class="form-control form-control-alternative"
                        v-model="records.qualifications_obtained"
                        @change="changeOtherQualifications()"
                    >
                        <option
                            :value="null"
                            disabled
                        >
                            --- Select One ---
                        </option>
                        <option value="mbbs">MBBS</option>
                        <option value="bds">BDS</option>
                        <option value="others">Others (Please, specify)</option>
                    </select>
                </base-input>
            </div>
            <div class="col-lg-4" v-if="showOtherQualifications">
                <base-input alternative=""
                    label="Other Qualifications"
                    placeholder="Enter Other Qualification Name"
                    input-classes="form-control-alternative"
                    v-model="records.other_qualifications_obtained"
                    required
                />
            </div>
            <div class="col-lg-4">
                <base-input
                    label="Degree Start Date"
                    addon-left-icon="ni ni-calendar-grid-58"
                    required

                >
                    <flat-picker
                        slot-scope="{focus, blur}"
                        @on-open="focus"
                        @on-close="blur"
                        :config="config"
                        class="form-control datepicker"
                        v-model="records.start_date"
                    >
                    </flat-picker>
                </base-input>
            </div>
            <div class="col-lg-4">
                <base-input
                    label="Degree End Date"
                    addon-left-icon="ni ni-calendar-grid-58"
                    required

                >
                    <flat-picker
                        slot-scope="{focus, blur}"
                        @on-open="focus"
                        @on-close="blur"
                        :config="config"
                        class="form-control datepicker"
                        v-model="records.end_date"
                    >
                    </flat-picker>
                </base-input>
            </div>
            <div class="col-lg-12">
                <base-alert :type="errorType" dismissible v-if='showInputErrorStatus'>
                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                    <span class="alert-inner--text">
                        {{errorMsg}}
                    </span>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </base-alert>
            </div>
            <div class="col-lg-12"></div>
            <div class="col-lg-12">
                <base-button
                    type="success"
                    @click="saveEduRecords()"
                >
                    <i class="fa fa-save"></i>
                    {{isloading ? 'Please Wait...' : 'Add Record'}}
                </base-button>

                <div class="right" v-if="hasRecords">
                    <base-button
                        type="danger"
                        @click="displayAddPage()"
                    >
                        <i class="fa fa-times"></i>
                        Cancel
                    </base-button>
                </div>
            </div>
        </div>


        <hr class="my-4"/>

        <div class="pl-lg-4">
            <base-button
                type="danger"
                @click="showPreviousPage()"
            >
                <i class="fa fa-arrow-left"></i>
                Previous
            </base-button>
            <div class="right" v-if="hasMedicalSchool">
                <base-button
                    type="success"
                    @click="showNextPage()"
                >
                    Continue
                    <i class="fa fa-arrow-right"></i>
                </base-button>
            </div>
        </div>

    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import EduRecordsData from '../ProfessionalData/EducationalRecords/EduRecordsData.vue';
    import flatPicker from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import swal from 'sweetalert2';// import sweet alert
    import Autocomplete from 'vuejs-auto-complete';

    export default {
        components: {
            flatPicker,
            EduRecordsData,
            Autocomplete
        },
        name: 'EduRecords',
        props: ['displayBioData', 'displaySpecData'],
        data() {
            return {
                eduRecords: [],
                paginatedItems: [],
                records: {
                    school_type: 'medical-school',
                    school_name: null,
                    school_id: null,
                    other_school_name: null,
                    qualifications_obtained: null,
                    start_date: null,
                    end_date: null,
                    other_qualifications_obtained: null,
                    country_id: null
                },
                pagination: {
                    total: null,
                    number: 1,
                    from: null,
                    perPage: this.$store.state.user.perPage // get per page count
                },
                institutions: [],
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                showOtherQualifications: false,
                showInputErrorStatus: false,
                showOtherInstitution: false,
                isLoadingBlade: true,
                showAddRecord: false,
                config: {
                    maxDate: new Date().fp_incr(1),
                    altInput: true,// eslint-disable-next-line
                    plugins: [new monthSelectPlugin({shorthand: true, dateFormat: "Y-m-d", altFormat: "M Y"})]
                }
            };
        },
        computed: {
            ...mapGetters(['locations', 'loadingBladeUrl']),
            hasPagination() {
                return this.paginatedItems && (this.paginatedItems.length > this.pagination.perPage)
                    ? true : false;
            },// eslint-disable-next-line
            hasMedicalSchool() {// check for medical school record
                for(let school of this.paginatedItems) {
                    if(school.school_type == 'medical-school') {
                        return true;
                    }
                }
                return false;
            },
            hasRecords() {// check for any records
                return this.eduRecords && this.eduRecords.length > 0;
            },
            showOtherSchoolName() {
                return this.records.country_id && (this.records.country_id == 164) && this.records.school_type
                        && (this.records.school_type == 'medical-school') ? false : true;
            },
            isOtherSchool() {
                let type = this.records.school_type;
                return type && (type != 'medical-school') && (type != 'pre-clinical-period') &&
                            (type != 'clinical-period') && (type != 'tertiary');
            }
        },
        methods: {
            ...mapActions(['getLocations', 'getInstitutions', 'saveUserEduRecords', 'getAssessmentInstitutions',
                'getUserEducationalRecords', 'deleteUserEduRecord']),
            displayAddPage() {
                return this.showAddRecord = ! this.showAddRecord;
            },
            changeCountry(e) {// get exact institutions
                this.records.school_name = null;
                this.records.other_school_name = null;
                this.records.school_id = null;
                this.institutions = [];
                if(! this.isOtherSchool) {
                    this.preloader();
                    this.getAssessmentInstitutions({ id : e.selectedObject.id }).then((res) => {
                        swal.close();
                        this.institutions = res.schools;
                        let other = { id : 0, name: 'Others (Specify)'};
                        this.institutions.push(other);
                        this.$refs.schoolAutocomplete.clear();
                    });
                }
            },
            updateSchool(e) {// update school
                this.showOtherInstitution = false;
                this.records.school_id = null;
                this.records.school_name = null;
                let data = e.selectedObject;
                this.records.school_id = data.id;
                this.records.school_name = data.school_name;
                if(data.id == 0) { this.showOtherInstitution = true; }// show other institution
            },
            showPreviousPage() {// go to next page
                return this.displayBioData(true);
            },
            showNextPage() {// go to next page
                return this.displaySpecData(true);
            },
            loadEducationalRecords() {// get edu records
                const id = this.$store.state.user.userDataId;
                if(id) {
                    this.getUserEducationalRecords({'id' : id}).then((res) => {
                        this.setPages(res);
                        this.isLoadingBlade = false;
                    });
                }
            },
            setPages(res) {// set paginated function
                this.eduRecords = [];
                this.paginatedItems = res;
                this.pagination.total = this.paginatedItems.length; // get items total
                let perPage = this.pagination.perPage; // get per page count
                if(this.paginatedItems.length > perPage) {// paginate
                    let numberOfPages = Math.ceil(this.paginatedItems.length / perPage);
                    for (let index = 1; index <= numberOfPages; index++) {
                        this.eduRecords.push(this.paginatedItems[index]);
                    }
                } else {
                    this.eduRecords = this.paginatedItems;
                }
            },
            paginate() {// paginate function
                this.eduRecords = [];
                this.pagination.from = null;
                let page = this.pagination.number;
                let perPage = this.pagination.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);
                this.pagination.from = from;//get first item number
                return this.eduRecords = this.paginatedItems.slice(from, to);
            },
            changeOtherQualifications() {// change other qualifications
                let value = this.records.qualifications_obtained;
                return this.showOtherQualifications = (value == 'others') ? true : false;
            },
            // changeOtherInstitution() {// change other Institution
            //     let value = this.records.school_id;
            //     if(value) {// add school name
            //         this.records.other_school_name = null;
            //         for(let institution of this.institutions) {
            //             if(institution.id == value) {
            //                 this.records.school_name = institution.school_name;
            //                 break;
            //             }
            //         }
            //     } else {
            //         this.showOtherInstitution = true;
            //         this.records.school_name = null;
            //     }
            // },
            saveEduRecords() {// save new edu records
                this.isloading = true;
                let data = this.validatedInputs();// validate inputs
                if(data) {// save edu records
                    this.preloader();// show loader
                    data.user_data_id = this.$store.state.user.basicProfile.id;// get user id
                    data.school_id = this.records.school_id;
                    this.saveUserEduRecords(data).then((res) => {
                        this.isloading = false;
                        if(res.status) {
                            this.showErrorStatus = false;
                            this.showAddRecord = false;
                            this.loadEducationalRecords(); // load EduRecords
                            this.emptyEduRecords();
                            swal.fire("Record Added!", "School record was added successfully", "success");
                        } else {
                            swal.fire("Error Occurred!", "An error occurred, kindly reload page and try again", "warning");
                            this.emptyEduRecords();
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        this.emptyEduRecords();
                        swal.fire("Error Occurred!", "An error occurred, kindly reload page and try again", "warning");
                    });
                } else {
                    this.isloading = false;
                }
            },
            validatedInputs() {
                let data = this.records;
                let eduRecord = { school_type: null, start_date: null, country_id: null,
                    end_date: null, school_name: null, qualifications_obtained: null };
                eduRecord.end_date  = data.end_date == null ? this.showInuptError('Degree\'s End Date') : data.end_date;
                eduRecord.start_date  = data.start_date == null ? this.showInuptError('Degree\'s Start Date') : data.start_date;
                // validate qualification obtained
                if((data.qualifications_obtained == null || data.qualifications_obtained == 'others')
                    && data.other_qualifications_obtained != null) {
                    eduRecord.qualifications_obtained = data.other_qualifications_obtained;
                } else if(data.qualifications_obtained != null && data.qualifications_obtained != 'others') {
                    eduRecord.qualifications_obtained = data.qualifications_obtained;
                } else if((data.qualifications_obtained == null || data.qualifications_obtained == 'others')
                    && data.other_qualifications_obtained == null) {
                    this.showInuptError('Qualification Obtained');
                }
                // validate institution obtained
                if(data.school_name == null && data.other_school_name != null) {
                    eduRecord.school_name = data.other_school_name;
                } else if(data.school_name != null) {
                    eduRecord.school_name = data.school_name;
                } else if(data.school_name == null && data.other_school_name == null) {
                    this.showInuptError('Institution\'s Name');
                }
                eduRecord.country_id  = data.country_id == null ? this.showInuptError('Country') : data.country_id;
                eduRecord.school_type = data.school_type == null ?
                    this.showInuptError('School\'s Type') : data.school_type;
                return this.isEmpty(eduRecord) ? eduRecord : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            emptyEduRecords() {
                return this.records = {
                    school_type: 'medical-school',
                    school_name: null,
                    school_id: null,
                    other_school_name: null,
                    qualifications_obtained: null,
                    start_date: null,
                    end_date: null,
                    other_qualifications_obtained: null,
                    country_id: null
                };
            },
            deleteEduRecord(id) {// delete User EduRecord function
                this.preloader();// show loader
                this.deleteUserEduRecord({'id' : id}).then((res) => {
                    if(res.status) {
                        swal.fire("Record Deleted!", "School record was removed successfully", "success");
                        this.loadEducationalRecords(); // load EduRecords
                    } else {
                        swal.fire("Error Occurred!", "An error occurred while deleting, try again", "warning");
                        this.loadEducationalRecords(); // load EduRecords
                    }
                }).
                catch(() => {
                    swal.fire("Network Failure", "Reload page and try again...", "warning");
                    this.loadEducationalRecords(); // load EduRecords
                });
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.getLocations();// get locations
            this.loadEducationalRecords();// load edu records
        },
    }
</script>

<style scoped>
    .right {
        float: right;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
</style>