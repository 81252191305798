<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div class="pl-lg-4">
                            
                            <div v-if="! hasNotPaidMwanLevy && ! isLoadingBlade">
                                <!-- Import AQ payment blade here -->
                                <AddQualPayment
                                    v-if="! showOtherPayment && application && application.code == 'additional-pgq'"
                                />

                                <!-- Import Change of Name payment blade here -->
                                <ChangeNamePayment
                                    v-if="! showOtherPayment && application && application.code == 'change-of-name'"
                                />

                                <!-- Import logs payment blade here -->
                                <LogsPayment
                                    v-if="! showOtherPayment && application && application.code == 'cert-good-standing'"
                                />

                                <!-- Import logs payment blade here -->
                                <TempRegPayment
                                    v-if="! showOtherPayment && application &&
                                    ((application.code == 'limited-reg-long') || (application.code == 'limited-reg'))"
                                />

                                <!-- Import Diaspora License payment blade here -->
                                <DiasporaLicensePayment
                                    v-if="! showOtherPayment && application && ! isLoadingBlade && ((application.code == 'additional-pgq-diaspora') 
                                            || (application.code == 'change-of-name-diaspora') || (application.code == 'full-aq-diaspora')
                                            || (application.code == 'full-intern-diaspora'))"
                                />

                                <!-- Import Other Payment blade here -->
                                <OtherPayment
                                    v-if="showOtherPayment"
                                />
                            </div>

                            <div class="col-lg-12" v-if="isLoadingBlade && ! hasNotPaidMwanLevy">
                                <div class="d-flex justify-content-center">
                                    <img :src="loadingBladeUrl" />
                                </div>
                            </div>

                            <div class="col-sm-12 text-center" v-if='showErrorStatus && ! hasNotPaidMwanLevy'>
                                <base-alert :type="errorType">
                                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                                    <span class="alert-inner--text">
                                        {{ errorMsg }}
                                    </span>
                                </base-alert>
                            </div>

                            <MwanPayment
                                :loadApplication="loadApplication"
                                v-if="hasNotPaidMwanLevy && ! isLoadingBlade"
                            />

                        </div>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'; //AddQualPayment
    import AddQualPayment from './PaymentDetails/AddQualPayment.vue';
    import OtherPayment from './PaymentDetails/OtherPayment.vue';
    import ChangeNamePayment from './PaymentDetails/ChangeNamePayment.vue';
    import LogsPayment from './PaymentDetails/LogsPayment.vue';
    import TempRegPayment from './PaymentDetails/TempRegPayment.vue';
    import DiasporaLicensePayment from './PaymentDetails/DiasporaLicensePayment.vue';
    import MwanPayment from '../../NewAccount/MwanPayment.vue';

    export default {
        components: {
            AddQualPayment,
            OtherPayment,
            ChangeNamePayment,
            LogsPayment,
            TempRegPayment,
            DiasporaLicensePayment,
            MwanPayment
        },
        name: 'Payment',
        data() {
            return {
                id: this.$route.params.id,
                application: {},
                isLoadingBlade: true,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false
            }
        },
        filters: {
            formatPrice(value) {
                if(value) {
                    let val = (value/1).toFixed(2).replace(',', '.');
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                } else {
                    return 'N/A';
                }
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'user']),
            showOtherPayment() {
                let validRoutes = 
                [
                    'additional-pgq', 'change-of-name', 'cert-good-standing', 'limited-reg-long', 'limited-reg', 'diaspora-license', 
                    'additional-pgq-diaspora', 'change-of-name-diaspora', 'full-aq-diaspora', 'full-intern-diaspora'
                ];
                // eslint-disable-next-line 
                return (! ($.inArray(this.application.code, validRoutes) > -1) ) ? true : false;
            },
            hasNotPaidMwanLevy() {// if doctor is medical women and has not paid levy
                const mwan = this.user && this.user.basic_profile && this.user.basic_profile.mwan_levy ? this.user.basic_profile.mwan_levy : false;
                return mwan && mwan.status && ! mwan.pay_status;
            }
        },
        methods: {
            ...mapActions(['getApplicationTypeId', 'saveApplicationTransaction', 'getOtherPayment', 'getDiasporaLicense']),
            loadApplication() {// get application
                this.getApplicationPaymentDetails();// get payment details
                // this.getDiasporaLicense().then((res) => {// check if doctor is eligible for diaspora license
                //     const record = res.application;
                //     if(record && record.license && ((record.license.status == 'submitted') || ((record.license.status == 'approved') 
                //         && (record.license.payment_status == 'paid')))) {
                //         window.location.href = '/new-application/diaspora-license';
                //     }
                //     this.getApplicationPaymentDetails();// get payment details
                // }).
                // catch(() => {
                //     this.isLoadingBlade = false;
                //     this.showErrorStatus = true;
                //     this.errorMsg = "Server Failure!, kindly reload the page...";
                //     this.errorType = 'danger';
                // }); 
            },
            getApplicationPaymentDetails() {// Application payment details
                this.isLoadingBlade = true;
                this.getOtherPayment().then((res) => {// check if user can pass to make payment
                    this.isLoadingBlade = false;
                    this.showErrorStatus = false; 
                    if(res.status) {// redirect payment
                        return window.location.href='/new-application-payment/'+res.application.id;
                    } else {
                        this.getPaymentDetails();// get payment details
                    }
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.showErrorStatus = true;
                    this.errorMsg = "Network Failure!", "Kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            getPaymentDetails() {// get payment details
                this.getApplicationTypeId({'id' : this.id}).then((res) => {
                    this.application = res;
                    this.isLoadingBlade = false;
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.showErrorStatus = true;
                    this.errorMsg = "Network Failure!", "Kindly reload the page...";
                    this.errorType = 'danger';
                });
            }
        },
        created() {
            this.loadApplication();// load application
        },
    }
</script>

<style scoped>
.right {
    float: right;
}
</style>