<template>
    <div>
        <RegistrationDetails 
            :displayNMALevy="displayNMALevy"
            :isDiasporaFullReg="isDiasporaFullReg"
            v-if="! isDiasporaFullReg || isVerified"
        />

        <AbroadAssociationPage 
            :displayNMALevy="displayNMALevy"
            :user="user"
            :hasAssociation="hasAssociation"
            :associations="associations"
            :isToVerifyMembership="isToVerifyMembership"
            v-if="isDiasporaFullReg && ! isVerified"
        />
        
    </div>
</template>

<script>
    import RegistrationDetails from './RegistrationDetails.vue'
    import AbroadAssociationPage from './AbroadAssociationPage/AbroadAssociationPage.vue'
import { mapActions, mapGetters } from 'vuex'

    export default {
        components: {
            RegistrationDetails,
            AbroadAssociationPage
        },
        name: 'Registration',
        props: ['displayNMALevy', 'isFullRegWithoutProvAqDiaspora', 'isFullRegWithoutProvInternDiaspora'],
        computed: {
            ...mapGetters(['user']),
            isDiasporaFullReg() {// check if appllication is diaspora application
                return this.isFullRegWithoutProvAqDiaspora || this.isFullRegWithoutProvInternDiaspora
            },
            basicProfile() {
                return this.user && this.user.basic_profile ? this.user.basic_profile : false;
            },
            associations() {// foreign medical associations
                return this.basicProfile ? this.basicProfile.associations : [];
            },
            hasAssociation() {
                let association = this.associations.filter(item => item.id == this.basicProfile.association_id);
                return association ? association[0] : null;
            },
            isToVerifyMembership() {
                return this.hasAssociation ? this.hasAssociation && (this.hasAssociation.status == 'verify') : false;
            },
            isVerified() {// check if user association is verified
                return this.basicProfile.association_id && (! this.isToVerifyMembership 
                        || (this.isToVerifyMembership && this.basicProfile && (this.basicProfile.association_status == 'verified')));
            }
        },
        methods: {
            ...mapActions(['fetchUserProfile']),
            proceedToPayment(status) {
                return this.isVerified = status;
            }
        },
        created() {
            this.fetchUserProfile();
        }
    }
</script>

<style scoped>
.right {
    float: right;
}
</style>