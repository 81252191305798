<template>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 text-center">
                <h5 class="text-muted mb-4 text-uppercase">
                    <span class="text-danger">
                        <i class="fa fa-info-circle"></i> Only PDF Files (Not More than 500KB) are supported for each file
                    </span>            
                </h5>
            </div>

            <div class="col-lg-3"></div>

            <div 
                class="col-lg-6 text-center borderLine" 
                v-for="(document, index) in documents"
                :key="index"
            >
                <br>
                <input 
                    type="file" 
                    id="file" style="display: none"
                    @change="onFileSelected"
                >
                <h4 class="height-50">
                    <span class="text-uppercase">{{document.name}}</span><br>
                    <small><em class="text-danger">{{ document.description }}</em></small>
                </h4><br>
                <base-progress 
                    v-if="loadPercent.show && (selectedFileName == document.filename)"
                    :type="loadPercent.type" 
                    :height="10" 
                    :value="loadPercent.value"
                    :striped="true"
                    :animated="loadPercent.animated"
                >
                </base-progress>
                <base-button 
                    size="sm" type="primary" 
                    v-if="! document.hasFile && (selectedFileName != document.filename)"
                    @click="$emit('selectFile', document.filename)"
                    :disabled="! showReSendButton"
                >
                    <i class="fa fa-hand-pointer"></i> Select File
                </base-button>
                <base-button 
                    tag="a" type="primary" 
                    size="sm" 
                    v-if="document.hasFile && ! showUploadButton || ((selectedFileName != document.filename) && document.hasFile)"
                    :href="document.url"
                    class="active" role="button" 
                    aria-pressed="true"
                    target="_blank"
                >
                    <i class="fa fa-eye"></i> View File
                </base-button>
                <base-button 
                    size="sm" type="warning" 
                    v-if="document.hasFile && ! showUploadButton || ((selectedFileName != document.filename) && document.hasFile)"
                    @click="$emit('selectFile', document.filename)"
                    :disabled="! showReSendButton"
                >
                    <i class="fa fa-pen"></i> Change File
                </base-button>
                <base-button 
                    size="sm" type="danger" 
                    v-if="showUploadButton && (selectedFileName == document.filename) && ! loadPercent.show"
                    @click="$emit('removeFile')"
                >
                    <i class="fa fa-times"></i> Remove File
                </base-button>            
                <br><br>                   
            </div>

            <div class="col-lg-12" v-if="isLicensePending">
                <hr>
                <base-button 
                    type="danger"
                    @click="$emit('backToAssocaition')"
                >
                    <i class="fa fa-arrow-left"></i> 
                    Back
                </base-button>
                <hr>
            </div>
        </div>
    </div>        
</template>

<script>
    export default {
        name: 'DiasporaDocument',
        props: ['documents', 'selectedFileName', 'loadPercent', 'showReSendButton', 'onFileSelected', 'showUploadButton', 'isLicensePending']
    }
</script>

<style scoped>
    .right {
        float: right;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>