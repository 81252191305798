<template>
    <div>
        <div class="row" v-if="records && records.length > 0">
            <div
                class="col-sm-2 text-center borderLine"
                v-for="(certificate, index) in records"
                :key="index"
                style="height: 240px;"
                v-show="certificate && certificate.certificate_type"
            >
                
                <div v-if="certificate && certificate.certificate_type">
                    <div class="d-flex justify-content-center">
                        <img :src="certificateImageUrl" width="90px"/>
                    </div>
                    <br>
                    <div class="d-flex justify-content-center">
                        <b class="font-size-13">
                            {{ certificate && certificate.certificate_type ? certificate.certificate_type.title : 'N/A' }}
                            <em>
                                {{ getLicenseYear(certificate) }}
                            </em>
                        </b>
                    </div>
                    <!-- <br v-if="! (certificateExpires(certificate.certificate_type) &&
                        certificate.application.valid_date)"> -->
                    <div class="d-flex justify-content-center">
                        <small
                            v-if="certificateExpires(certificate.application) && certificate.application.valid_date"
                        >
                            Expires on
                            {{ getExpiryDate(certificate.certificate_type, certificate.application.valid_date) | getDateFormat }}
                        </small>

                        <br>
                    </div>

                    <div
                        class="d-flex justify-content-center"
                        v-if="certificate && certificate.application && certificate.application.has_good_standing 
                                    && certificate.certificate_type.code == 'logs-license'"
                    >
                        <badge type="primary">
                            {{ certificate.application.has_good_standing.country }}
                            ({{ certificate.application.application_date | getYear }})
                        </badge>
                    </div>

                    <div class="d-flex justify-content-center">
                        <form :action="printCertificateUrl" method="POST" target="_blank">
                            <input type="hidden" name="user_id" :value="user.id" />
                            <input type="hidden" name="id" :value="certificate.application.id" />
                            <input type="hidden" name="cert_id" :value="certificate.certificate_type.id" />

                            <base-button
                                size="sm"
                                type="success"
                                nativeType="submit"
                            >
                                <i class="fa fa-print"></i>
                                Open Document
                            </base-button>
                        </form>
                    </div>
                </div>
                <br>
            </div>

            <div class="col-sm-12">
                <div
                    class="card-footer d-flex justify-content-center bg-transparent"
                    v-if="hasPagination"
                        @click="paginate"
                >
                    <base-pagination
                        :total="pagination.total"
                        align="center"
                        v-model="pagination.number"
                        :perPage="pagination.perPage"

                    >
                    </base-pagination>
                </div>
            </div>

        </div>
    </div>

</template>

<script>
    import { mapGetters } from 'vuex';
    import moment from 'moment';

    export default {
        name: 'CertificateData',
        props: ['records', 'hasPagination', 'paginate', 'pagination'],
        filters: {
            getDateFormat(value) {
                return moment(value).format('Do MMM, YYYY');
            },
            getYear(value) {
                return value ? moment(value).format('YYYY') : 'N/A';
            }
        },
        computed: {
            ...mapGetters(['certificateImageUrl', 'printCertificateUrl', 'user'])
        },
        methods: {
            getDateFormat(value) {
                return moment(value).format('YYYY');
            },
            getLicenseYear(value) {
                if (value && value.certificate_type && ((value.certificate_type.id == 27) || (value.certificate_type.id == 1) 
                        || (value.certificate_type.code == 'diaspora-license'))) {
                    if(value.license_year) {
                        return '('+value.license_year+')';
                    } else {
                        let year = this.getDateFormat(value.valid_date);
                        return '('+ year +')';
                    }
                }
            },
            certificateExpires(certificate) {
                const codes = [ 'annual-license', 'provisional-license', 'provisional-registration', 'limited-registration', 'annual-alternative',
                            'revalidation-provisional-registration', 'diaspora-license' ];
                // let ids = [ 1, 8, 19, 20, 27, 26, 29, 30 ];// eslint-disable-next-line
                if($.inArray(certificate.code, codes) > -1) {
                    return true;
                } else {
                    return false;
                }
            },
            getExpiryDate(certificate, date) {// get expiry date
                const codes = [ 'annual-license', 'provisional-license', 'diaspora-license' ];
                // let ids = [ 1, 8 ];// eslint-disable-next-line
                if($.inArray(certificate.code, codes) > -1) {
                    return moment(date).year()+'-12-31';
                } else {
                    return date;
                }
            }
        }
    }
</script>

<style scoped>
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
    .font-size-13 {
        font-size: 13px;
    }
</style>
